import {
  SET_USER_FILTER,
  SET_SELECTED_FILTER,
  SET_SUB_FILTER,
  SET_ACTIVE_USER_FILTER,
  RESET_FILTERS,
} from './actionTypes'

export function setUserFilter(filters = {}, tab = '') {
  return {
    type: SET_USER_FILTER,
    payload: { filters, tab },
  }
}

export function setSelectedFilter(filters = {}, tab = '') {
  return {
    type: SET_SELECTED_FILTER,
    payload: { filters, tab },
  }
}

export function setSubFilter(subFilters = [], tab = '') {
  return {
    type: SET_SUB_FILTER,
    payload: { subFilters, tab },
  }
}

export function setActiveUserFilter(filters = {}, tab = '') {
  return {
    type: SET_ACTIVE_USER_FILTER,
    payload: { filters, tab },
  }
}

export function removeFilters(tab = '') {
  return {
    type: RESET_FILTERS,
    payload: { tab },
  }
}
