import {
  GET_WORKFLOW_DATA_FULFILLED,
  GET_WORKFLOW_DATA_REJECTED,
  GET_WORKFLOW_DATA_PENDING,
  GET_CUSTOM_COLUMNS_PENDING,
  GET_CUSTOM_COLUMNS_FULFILLED,
  GET_CUSTOM_COLUMNS_REJECTED,
  SET_CUSTOM_COLUMNS_PENDING,
  SET_CUSTOM_COLUMNS_FULFILLED,
  SET_CUSTOM_COLUMNS_REJECTED,
  GET_WORKFLOW_ASIGNEE_LIST_PENDING,
  GET_WORKFLOW_ASIGNEE_LIST_FULFILLED,
  GET_WORKFLOW_ASIGNEE_LIST_REJECTED,
  GET_WORKFLOW_QAASIGNEE_LIST_PENDING,
  GET_WORKFLOW_QAASIGNEE_LIST_FULFILLED,
  GET_WORKFLOW_QAASIGNEE_LIST_REJECTED,
  CHANGE_IMAGE__WORKFLOW_PENDING,
  CHANGE_IMAGE__WORKFLOW_FULFILLED,
  CHANGE_IMAGE__WORKFLOW_REJECTED,
  ADD_QA_NOTES_PENDING,
  ADD_QA_NOTES_FULFILLED,
  ADD_QA_NOTES_REJECTED,
  ADD_NOTES_PENDING,
  ADD_NOTES_FULFILLED,
  ADD_NOTES_REJECTED,
  ADD_TAGS_PENDING,
  ADD_TAGS_FULFILLED,
  ADD_TAGS_REJECTED,
  EDIT_TAGS_PENDING,
  EDIT_TAGS_FULFILLED,
  EDIT_TAGS_REJECTED,
  GET_TAGS_LIST_PENDING,
  GET_TAGS_LIST_FULFILLED,
  GET_TAGS_LIST_REJECTED,
  GET_MANAGE_TAGS_LIST_PENDING,
  GET_MANAGE_TAGS_LIST_FULFILLED,
  GET_MANAGE_TAGS_LIST_REJECTED,
  DELETE_TAGS_PENDING,
  DELETE_TAGS_FULFILLED,
  DELETE_TAGS_REJECTED,
  CHECK_WITH_SCRIPT_TAGS_FULFILLED,
  CHECK_WITH_SCRIPT_TAGS_REJECTED,
  CHECK_WITH_SCRIPT_TAGS_PENDING,
  APPLY_IMAGE_TAGS_PENDING,
  APPLY_IMAGE_TAGS_FULFILLED,
  APPLY_IMAGE_TAGS_REJECTED,
  SET_IMAGE_TO_ARCHIVE_PENDING,
  SET_IMAGE_TO_ARCHIVE_FULFILLED,
  SET_IMAGE_TO_ARCHIVE_REJECTED,
  SEND_TO_PIPELINE_PENDING,
  SEND_TO_PIPELINE_FULFILLED,
  SEND_TO_PIPELINE_REJECTED,
  UPDATE_WORKFLOW_TABLE_IMAGE_THUMBNAIL,
  SET_TOP_LEVEL_STUDIO_FILTER,
} from './actionTypes'
import axios from 'axios'
import apiConfig, { appEnv } from '../../apiConfig'
import { showNotification } from '../Notification/actionCreator'
import { generateCustomColumnVisibilityModel } from '../../containers/Workflow/constants'
import { isString, compact } from 'lodash'
import { closeZoomedImage } from '../TcinInfo/actionCreator'
import { initializeSelectedFilter } from '../Filters/actionCreator'

const {
  getWorkflowItemsApi,
  getWorkflowOperationsApi,
  getUserCustomColumns,
  addTagsApi,
  getManageTagsApi,
  archiveImageApi,
  usersAdGroupBaseUrl,
  unlinkTcinUrl,
} = apiConfig

export const getWorkflowDataFulfilled = (data = {}) => {
  return {
    type: GET_WORKFLOW_DATA_FULFILLED,
    payload: data,
  }
}

export const updateTableThumbnail = (data = {}) => {
  return {
    type: UPDATE_WORKFLOW_TABLE_IMAGE_THUMBNAIL,
    payload: data,
  }
}

export const updateTopLevelStudio = (data = []) => {
  return {
    type: SET_TOP_LEVEL_STUDIO_FILTER,
    payload: data,
  }
}

export const getCustomColumnsFulfilled = (data = {}) => {
  return {
    type: GET_CUSTOM_COLUMNS_FULFILLED,
    payload: data,
  }
}

export const getWorkflowAsigneeListFulfilled = (data = {}) => {
  return {
    type: GET_WORKFLOW_ASIGNEE_LIST_FULFILLED,
    payload: data,
  }
}
export const getWorkflowQaAsigneeListFulfilled = (data = {}) => {
  return {
    type: GET_WORKFLOW_QAASIGNEE_LIST_FULFILLED,
    payload: data,
  }
}
export const getCustomColumnsAction =
  (accessToken = '', lanId = '', tableTab = '') =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_CUSTOM_COLUMNS_PENDING,
    })
    return axios
      .get(getUserCustomColumns, {
        params: {
          key: apiConfig.apiKey,
          user_id: lanId,
          view: tableTab,
        },
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data: { column_list = [] } = {} } = response
        const columnVisibilityModel = generateCustomColumnVisibilityModel(
          column_list ? column_list : [],
          tableTab
        )
        dispatch({
          type: GET_CUSTOM_COLUMNS_FULFILLED,
          payload: { tableTab, columnVisibilityModel, column_list },
        })
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown error occured to get your custom columns. Applying Default Columns.'
            : 'An Unknown error occured to get your custom columns. Applying Default Columns.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: GET_CUSTOM_COLUMNS_REJECTED,
          payload: error,
        })
      })
  }

export const updateWorkflowCustomColumnsAction =
  (accessToken = '', lanId = '', tableTab = '', columnSelectionModel = '') =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_CUSTOM_COLUMNS_PENDING,
    })
    const newColumnList = []
    for (const [key, value] of Object.entries(columnSelectionModel)) {
      if (value.selected) {
        newColumnList.push(key)
      }
    }
    const body = {
      user_id: lanId.toUpperCase(),
      column_list: newColumnList,
    }

    return axios
      .post(getUserCustomColumns, body, {
        params: {
          view: tableTab,
          key: apiConfig.apiKey,
        },
        headers: { Authorization: accessToken },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: SET_CUSTOM_COLUMNS_FULFILLED,
          payload:
            data[`workflow_${tableTab.toLowerCase()}_columns`].column_list,
        })
        dispatch({
          type: 'GET_CUSTOM_COLUMNS_FULFILLED',
          payload: {
            tableTab,
            columnVisibilityModel: columnSelectionModel,
            newColumnList,
          },
        })
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown error occured while saving Custom Columns, Please contact Support.'
            : 'An Unknown error occured while saving Custom Columns, Please contact Support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: SET_CUSTOM_COLUMNS_REJECTED,
          payload: error,
        })
      })
  }

export const getWorkflowDataAction =
  (
    userId = '',
    accessToken = '',
    workflowTab = '',
    page = '',
    pageSize = '',
    filters = {},
    sort_by = 'image_name',
    sort_order = 'asc',
    successCallback = () => {}
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_WORKFLOW_DATA_PENDING,
      payload: { workflowTab },
    })
    return axios
      .post(
        getWorkflowItemsApi,
        {
          phase: workflowTab,
          filters,
        },
        {
          params: {
            key: apiConfig.apiKey,
            page: page,
            size: pageSize,
            sort_by,
            sort_order,
          },
          headers: {
            ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
            Authorization: accessToken,
          },
        }
      )
      .then((response) => {
        const { data = {} } = response
        dispatch(
          getWorkflowDataFulfilled({
            ...data,
            workflowTab: workflowTab,
            filters: data.filters,
          })
        )
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown error occured while fetching workflow Data, Please contact Support.'
            : 'An Unknown error occured while fetching workflow Data, Please contact Support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: GET_WORKFLOW_DATA_REJECTED,
          payload: error,
        })
      })
      .finally(() => {
        successCallback()
      })
  }

export const addQANotesAction =
  (accessToken = '', payload = {}, userId = '', successCallback = () => {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_QA_NOTES_PENDING,
    })
    return axios
      .put(getWorkflowOperationsApi, payload, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        successCallback()
        dispatch({
          type: ADD_QA_NOTES_FULFILLED,
          payload: data,
        })
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: ADD_QA_NOTES_REJECTED,
          payload: error,
        })
      })
  }

export const addNotesAction =
  (accessToken = '', payload = {}, userId = '', successCallback = () => {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_NOTES_PENDING,
    })
    return axios
      .put(getWorkflowOperationsApi, payload, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: ADD_NOTES_FULFILLED,
          payload: data,
        })
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: ADD_NOTES_REJECTED,
          payload: error,
        })
      })
      .finally(() => {
        successCallback()
      })
  }

export const changeImageTagsAction =
  (accessToken = '', payload = {}, userId = '', successCallback = () => {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: APPLY_IMAGE_TAGS_PENDING,
    })
    return axios
      .put(`${addTagsApi}/apply_tags`, payload, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: APPLY_IMAGE_TAGS_FULFILLED,
          payload: data,
        })
        successCallback(data)
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: APPLY_IMAGE_TAGS_REJECTED,
          payload: error,
        })
      })
  }

export const addTagsAction =
  (accessToken = '', payload = {}, userId = '', successCallback = () => {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_TAGS_PENDING,
    })
    return axios
      .post(addTagsApi, payload, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: ADD_TAGS_FULFILLED,
          payload: data,
        })
      })
      .catch((error) => {
        const { response = {} } = error
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: ADD_TAGS_REJECTED,
          payload: response,
        })
      })
      .finally(() => {
        successCallback()
      })
  }

export const editTagsAction =
  (
    accessToken = '',
    payload = {},
    userId = '',
    tagId,
    successCallback = () => {}
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_TAGS_PENDING,
      payload: tagId,
    })
    return axios
      .put(`${addTagsApi}/${tagId}`, payload, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: EDIT_TAGS_FULFILLED,
          payload: data,
        })
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: EDIT_TAGS_REJECTED,
          payload: { error, tagId },
        })
      })
      .finally(() => {
        successCallback()
      })
  }

export const setUseWithScriptAction =
  (accessToken = '', tagId = '', payload = {}, userId = '') =>
  async (dispatch, getState) => {
    dispatch({
      type: CHECK_WITH_SCRIPT_TAGS_PENDING,
      payload: tagId,
    })
    return axios
      .put(`${addTagsApi}/${tagId}`, payload, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: CHECK_WITH_SCRIPT_TAGS_FULFILLED,
          payload: data,
        })
        dispatch(showNotification(true, 'Tag modified successfully', 'info'))
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: CHECK_WITH_SCRIPT_TAGS_REJECTED,
          payload: { error, tagId },
        })
      })
  }

export const getManageTagsAction =
  (accessToken = '') =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_MANAGE_TAGS_LIST_PENDING,
    })
    return axios
      .get(getManageTagsApi, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: GET_MANAGE_TAGS_LIST_FULFILLED,
          payload: data,
        })
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: GET_MANAGE_TAGS_LIST_REJECTED,
          payload: error,
        })
      })
  }

export const getTagsAction =
  (accessToken = '') =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_TAGS_LIST_PENDING,
    })
    return axios
      .get(addTagsApi, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: GET_TAGS_LIST_FULFILLED,
          payload: data,
        })
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: GET_TAGS_LIST_REJECTED,
          payload: error,
        })
      })
  }

export const deleteTagsAction =
  (accessToken = '', payload = {}, userId = '') =>
  async (dispatch, getState) => {
    dispatch({
      type: DELETE_TAGS_PENDING,
      payload: payload,
    })
    return axios
      .delete(addTagsApi, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
        data: { tag_ids: payload },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch(showNotification(true, 'Tag deleted successfully', 'info'))
        dispatch({
          type: DELETE_TAGS_FULFILLED,
          payload: { ids: payload, data: Array.isArray(data) ? data : [] },
        })
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: DELETE_TAGS_REJECTED,
          payload: error,
        })
      })
  }

export const changeImageWorkflowAction =
  (
    accessToken = '',
    payload = {},
    userId = '',
    workflowTab = '',
    page = '',
    pageSize = '',
    filters = {},
    successCallback = () => {},
    searchedValue = '',
    studioList = [],
    order = 'asc',
    orderBy = 'image_name'
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: CHANGE_IMAGE__WORKFLOW_PENDING,
    })
    return axios
      .put(getWorkflowOperationsApi, payload, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: CHANGE_IMAGE__WORKFLOW_FULFILLED,
          payload: data,
        })
        successCallback()
        setTimeout(() => {
          dispatch(
            getWorkflowDataAction(
              userId,
              accessToken,
              workflowTab,
              page,
              pageSize,
              {
                ...filters,
                ...(studioList.length && {
                  photo_studios: [
                    ...(filters?.photo_studios || []),
                    ...studioList,
                  ],
                }),
                ...(searchedValue && {
                  image_names: [
                    ...new Set(compact(searchedValue.split(/, |,/))),
                  ],
                }),
              },
              orderBy,
              order
            )
          )
        }, 1000)
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: CHANGE_IMAGE__WORKFLOW_REJECTED,
          payload: error,
        })
      })
  }

export const setImageToArchive =
  (
    accessToken = '',
    payload = {},
    userId = '',
    workflowTab = '',
    page = '',
    pageSize = '',
    filters = {},
    isArchived = true,
    searchedValue = '',
    successCallback = () => {}
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_IMAGE_TO_ARCHIVE_PENDING,
    })
    return axios
      .post(archiveImageApi, payload, {
        params: {
          archive: !isArchived,
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch({
          type: SET_IMAGE_TO_ARCHIVE_FULFILLED,
          payload: data,
        })
        successCallback()
        dispatch(closeZoomedImage())
        setTimeout(() => {
          dispatch(
            getWorkflowDataAction(
              userId,
              accessToken,
              workflowTab,
              page,
              pageSize,
              {
                ...filters,
                ...(searchedValue && {
                  image_names: [
                    ...new Set(compact(searchedValue.split(/, |,/))),
                  ],
                }),
              }
            )
          )
        }, 1000)
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: SET_IMAGE_TO_ARCHIVE_REJECTED,
          payload: error,
        })
      })
  }

export const sendToPipelineAction =
  (
    accessToken = '',
    payload = {},
    userId = '',
    workflowTab = '',
    page = '',
    pageSize = '',
    filters = {},
    successCallback = () => {}
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: SEND_TO_PIPELINE_PENDING,
    })
    return axios
      .post(`${getWorkflowOperationsApi}/sent_to_pipeline`, payload, {
        params: {
          key: apiConfig.apiKey,
        },
        headers: {
          ...(appEnv === 'dev' && { 'x-tgt-lanId': userId }),
          Authorization: accessToken,
        },
      })
      .then((response) => {
        const { data = {} } = response
        successCallback()
        setTimeout(() => {
          dispatch(
            getWorkflowDataAction(
              userId,
              accessToken,
              workflowTab,
              page,
              pageSize,
              filters
            )
          )
        }, 1000)
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: SEND_TO_PIPELINE_REJECTED,
          payload: error,
        })
      })
  }

export const getAsigneeList =
  (accessToken = '', adGroups = []) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_WORKFLOW_ASIGNEE_LIST_PENDING,
    })
    return axios
      .get(`${usersAdGroupBaseUrl}/ad_groups`, {
        params: {
          ad_groups: adGroups.join(','),
          key: apiConfig.apiKey,
        },
        headers: { Authorization: accessToken },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch(getWorkflowAsigneeListFulfilled(data))
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: GET_WORKFLOW_ASIGNEE_LIST_REJECTED,
          payload: error,
        })
      })
  }

export const getQaAssigneeList =
  (accessToken = '', adGroups = []) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_WORKFLOW_QAASIGNEE_LIST_PENDING,
    })
    return axios
      .get(`${usersAdGroupBaseUrl}/ad_groups`, {
        params: {
          ad_groups: adGroups.join(','),
          key: apiConfig.apiKey,
        },
        headers: { Authorization: accessToken },
      })
      .then((response) => {
        const { data = {} } = response
        dispatch(getWorkflowQaAsigneeListFulfilled(data))
      })
      .catch((error) => {
        const { response: { data: { details = '', message = '' } = {} } = {} } =
          error
        const errorMessage =
          isString(details) || isString(message)
            ? details.trim() ||
              message.trim() ||
              'An Unknown Error Occured. Please contact support.'
            : 'An Unknown Error Occured. Please contact support.'
        dispatch(showNotification(true, errorMessage, 'error'))
        dispatch({
          type: GET_WORKFLOW_QAASIGNEE_LIST_REJECTED,
          payload: error,
        })
      })
  }

export const unlinkMultiTcin =
  (accessToken = '', tcinFileName = '', callBack = () => {}, lanId = '') =>
  async (dispatch, getState) => {
    return axios
      .post(
        `${unlinkTcinUrl}`,
        {
          file_names: [tcinFileName],
        },
        {
          params: {
            key: apiConfig.apiKey,
          },
          headers: {
            Authorization: accessToken,
            'x-tgt-lanid': lanId,
          },
        }
      )
      .then((response) => {
        callBack(true)
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'An Unknown Error Occured. Please contact support',
            'error'
          )
        )
      })
  }
