/* eslint-disable multiline-ternary */
import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  Drawer,
  ToggleButtonGroup,
  Typography,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormGroup,
  Checkbox,
  Skeleton,
  Alert,
} from '@mui/material'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@mui/system'
import {
  cloneDeep,
  isEmpty,
  uniq,
  compact,
  remove,
  find,
  debounce,
  omitBy,
} from 'lodash'
import { formatDateTime } from '../../helpers/dateHelper'
import { useAuth } from '@praxis/component-auth'
import {
  workflowColumns,
  generateDefaultColumnVisibilityModel,
  generateCustomColumnVisibilityModel,
} from './constants'
import CustomizeColumnsPanel from './CustomizeColumnsPanel'
import {
  getWorkflowDataAction,
  updateWorkflowCustomColumnsAction,
  addQANotesAction,
  addNotesAction,
  addTagsAction,
  editTagsAction,
  sendToPipelineAction,
  deleteTagsAction,
  setUseWithScriptAction,
  changeImageTagsAction,
  setImageToArchive,
  changeImageWorkflowAction,
  getManageTagsAction,
} from '../../store/Workflow/actionCreator'
import {
  setUserFilter,
  setSelectedFilter,
  setSubFilter,
  setActiveUserFilter,
  removeFilters,
} from '../../store/Filters/actionCreator'
import { isDeepEmpty, isSafari } from '../../helpers/filter'
import {
  Button,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  Chip,
  Menu,
  MenuItem,
  Badge,
  Divider,
  CircularProgress,
  FormLabel,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Stack from '@mui/material/Stack'
import CreateIcon from '@material-ui/icons/Create'
import axios from 'axios'
import DoneIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import ImageIcon from '@material-ui/icons/Image'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import AssignmentIcon from '@mui/icons-material/Assignment'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import TuneIcon from '@mui/icons-material/Tune'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { workflowCsvMapper } from './workflowMappers'
import CancelIcon from '@material-ui/icons/Cancel'
import _without from 'lodash/without'
import { CSVLink } from 'react-csv'
import CloseIcon from '@material-ui/icons/Close'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {
  imageDownloadHost,
  retouchingQAADGroup,
  rolewiseImageStatusList,
  workflowToolbarContents,
  adminADGroup,
  retoucherADGroup,
  retouchingLeadADGroup,
  producerADGroup,
  artDirectorADGroup,
} from '../../constants/common'
import { intersectionBy, toUpper } from 'lodash'
import ConfirmationDailog from '../../components/ConfirmationDailog'
import CustomTable from '../../components/CustomTable'
import TableToolbar from './TableToolbar'
import { formatDate } from '../TcinInfo/mapper'
import { showNotification } from '../../store/Notification/actionCreator'
import apiConfig from '../../apiConfig'
import { downloadPreviewImage } from '../../helpers/imageDownloadHelper'
import { generateRetouchContactSheet } from './utils'
import { setFilesForDownloads } from '../../store/Downloads/actionCreator'
import WorkflowFlyoutContent from './WorkflowFlyoutContent'

const useStyles = makeStyles((theme) => ({
  actionsDrawer: {
    width: 'auto',
    backgroundColor: '#366CD9 !important',
    color: '#FFFFFF !important',
    padding: '10px 32px 10px 32px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row !important',
    left: '65px !important',
  },
  tagsbottomAction: {
    width: 'calc(100% + 48px)',
    marginLeft: '-24px',
    color: '#FFFFFF !important',
    padding: '10px 32px 10px 32px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row !important',
    position: 'absolute',
    top: 'auto',
    bottom: '42px',
  },
  footerButtonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  inputProps: {
    height: '30px',
    padding: '8px',
    fontSize: '14px',
  },
  inputWidth: {
    width: '400px',
    marginRight: '32px',
  },
  inputFindTCIN: {
    transform: 'translate(14px, 16px) scale(1)',
    fontSize: '14px',
  },
  footerButton: {
    color: '#FFFFFF',
    fontSize: '16px',
    marginRight: '32px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    textTransform: 'capitalize !important',
  },
  workflowDrawer: {
    width: '40%',
    maxWidth: '1000px',
    padding: 24,
  },
  chipsText: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
  },
  activeChips: {
    background: '#D3F3FF',
    border: ' 1px solid #333333',
    color: '#333333',
    '&:hover': {
      backgroundColor: '#B2E0F1 !important',
      color: '#333333 !important',
      border: ' 1px solid #333333 !important',
    },
    '&:focus': {
      backgroundColor: '#D3F3FF !important',
      color: '#333333 !important',
      border: ' 1px solid #333333 !important',
    },
  },
  outlinedChips: {
    color: '#999999 !important',
    border: '1px solid #999999',
    background: 'transparent !important',
    '&:focus': {
      color: '#999999 !important',
      border: '1px solid #999999',
      background: 'transparent !important',
    },
  },
  moreFilters: {
    border: ' 1px solid #366CD9',
    color: '#366CD9',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    '&:hover , &:focus': {
      backgroundColor: 'rgba(25, 118, 210, 0.04) !important',
    },
  },
  clearButton: {
    color: '#366CD9',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'capitalize !important',
    '&:hover': {
      outline: 'none',
      background: 'none !important',
    },
  },
  dissableButton: {
    cursor: 'not-allowed !important',
    pointerEvents: 'unset !important',
    color: '#666666 !important',
  },
  filterDrawer: {
    '& >div': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  tagsDrawer: {
    width: '80% !important',
    maxWidth: '1100px',
  },
  filterGrid: {
    margin: '24px 0',
  },
  zeroTop: {
    marginTop: '0 !important',
  },
  filterByContainer: {
    paddingBottom: '0 !important',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 'calc(100% - 150px)',
  },
  filterByFooter: {
    position: 'absolute',
    maxHeight: '76px',
    bottom: '42px',
    backgroundColor: '#ffffff',
    width: '100%',
    borderTop: '1px solid #DDDDDD',
    left: '0',
    padding: '24px',
  },
  tagsLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    color: '#333333',
    marginBottom: '24px',
    display: 'block',
  },
  drawerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '23px',
    flex: '1',
    color: '#333333',
    margin: '0',
  },
  closeIcon: {
    color: '#ffffff',
    padding: '0 !important',
    marginRight: '-9px !important',
  },
  selectControl: {
    width: '100%',
  },
  drawerContainer: {
    padding: '40px 0',
  },
  workflowDrawerContainer: {
    margin: '40px 0',
  },
  tagsDrawerContainer: {
    padding: '24px 0',
  },
  stausButton: {
    height: '40px',
    width: '120px',
    fontSize: '14px',
    fontWeight: '500',
  },
  inlineButton: {
    width: '80px !important',
  },
  primaryButton: {
    backgroundColor: '#366CD9',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
    '&:focus': {
      backgroundColor: 'rgb(17, 82, 147) !important',
      color: '#ffffff !important',
    },
  },
  pipeLineButton: {
    padding: '10px 12px',
    minWidth: '140px',
  },
  buttonGroup: {
    marginTop: '24px',
  },
  mwButtonGroup: {
    marginBottom: '40px',
  },
  drawerHeaderDivider: {
    marginTop: '24px',
    width: `calc(100% + 24px)`,
    marginLeft: '0px',
    '&:before': {
      content: "''",
      width: '24px',
      height: '1px',
      display: 'block',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.12)',
      marginLeft: '-24px',
    },
  },
  manageTagsActionFooter: {
    width: '-webkit-fill-available',
    position: 'fixed',
    bottom: '0',
    border: '1px solid #DDDDDD',
    padding: '10px 24px',
    marginLeft: '-24px',
    background: '#ffffff',
  },
  manageTagsInput: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
  },
  padding_left_right_null: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  padding_right_null: {
    paddingRight: '0 !important',
    paddingLeft: '24px !important',
  },
  tagsHeaderColumn: {
    fontWeight: '500 !important',
    fontSize: '14px !important',
    lineHeight: '17px !important',
    color: '#333333 !important',
  },
  nonExpandedIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandedIcon: {
    transform: 'rotate(180deg)',
  },
  accordianSummaryRoot: {
    padding: 0,
    margin: 0,
  },
  accordianSummaryContent: {
    alignItems: 'center',
  },
  tagsBodyColumn: {
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: '#333333 !important',
    padding: '10px 0 !important',
  },
  content: {
    flexGrow: 1,
    margin: '16px 0',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    paddingTop: 24,
  },
  buttons: {
    display: 'flex',
    gap: 24,
    marginBottom: 48,
  },
  filterDrop: {
    marginTop: '10px',
  },
  paddingZero: {
    padding: '0 !important',
  },
  margin_left_sm: {
    marginLeft: '16px',
  },
  tagCheck: {
    minWidth: 'unset !important',
  },
  margin_bottom_md: {
    marginBottom: '24px',
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const WorkflowTable = React.forwardRef((props, ref) => {
  const {
    tableTab = '',
    searchedValue = '',
    uploadsInProgress = {},
    searchKeywordValue = '',
    searchType = '',
  } = props
  const auth = useAuth()
  const {
    session: {
      userInfo: {
        accessToken = '',
        lanId = '',
        memberOf = [],
        displayname = '',
      } = {},
    } = {},
  } = auth
  const [createTagexpanded, setCreateTagExpanded] = React.useState(false)
  const [isContactSheetsDownloading, setContactSheetsDownloading] =
    React.useState(false)
  const [openDrawer, setOpenDrawer] = React.useState('')
  const [openDrawerOrigin, setOpenDrawerOrigin] = React.useState('')
  const dispatch = useDispatch()
  const classes = useStyles()
  const [selection, setSelection] = React.useState({
    NEW: [],
    RETOUCH: [],
    COLOR: [],
    HOLD: [],
    REVIEW: [],
    APPROVED: [],
  })
  const customTableRef = React.useRef(null)
  const openedWindow = React.useRef(null)
  const downloadList = React.useRef([])
  const [customizeColumnsIsOpen, setCustomizeColumnsIsOpen] =
    React.useState(false)
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(50)
  const [columns, setcolumns] = React.useState(cloneDeep(workflowColumns))
  const [
    isLoadingTags,
    isLoadingManageTags,
    isEditingTags,
    isAddingTags,
    isDeletingTags,
    isApplyingTags,
    isLoadingNotes,
    tagsDeleting,
    isLoading,
    tagsList,
    manageTagsList,
    asigneeList,
    qaAsigneeList,
    filterBy,
    columnVisibilityModel,
    columnSelectionModel,
    column_list,
    studioList,
    rows,
    subTabCount,
    totalRowCount,
    subChipFilter,
    selectedFilters,
    userSelectedFilter,
    activeUserSelectedFilter,
  ] = useSelector((state) => {
    const {
      workflowReducer: {
        isLoadingTags = false,
        isLoadingManageTags = false,
        isEditingTags = {},
        isAddingTags = false,
        isDeletingTags = false,
        isLoadingNotes = false,
        tagsDeleting = false,
        isApplyingTags = false,
        isLoading = false,
        tagsList = [],
        manageTagsList = [],
        asigneeList = [],
        qaAsigneeList = [],
        filters = [],
        columnVisibilityModel = {},
        columnSelectionModel = {},
        customColumnsList = {},
        topStudioList = [],
        rows = [],
        subTabCount = {},
        totalRowCount = 0,
      } = {},
      filtersReducer: {
        subChipFilter = [],
        selectedFilter = {},
        userSelectedFilter = {},
        activeUserSelectedFilter = {},
      } = {},
    } = state
    return [
      isLoadingTags,
      isLoadingManageTags,
      isEditingTags,
      isAddingTags,
      isDeletingTags,
      isApplyingTags,
      isLoadingNotes,
      tagsDeleting,
      isLoading,
      tagsList,
      manageTagsList,
      asigneeList,
      qaAsigneeList,
      filters,
      columnVisibilityModel,
      columnSelectionModel,
      customColumnsList,
      topStudioList,
      rows,
      subTabCount,
      totalRowCount,
      subChipFilter,
      selectedFilter,
      userSelectedFilter,
      activeUserSelectedFilter,
    ]
  })

  const [columnView, setColumnView] = React.useState('custom')

  // rowCountstate being set to undefined while loading data will cause the page to reset to 0.
  // Following lines are here to prevent `rowCountState` from being undefined during api loading
  const csvBtn = React.useRef()
  const [csvData, setCsvData] = React.useState({
    headers: [],
    data: false,
  })
  const [imageWorkflow, setImageWorkflow] = React.useState({})
  const [imageTagsList, setImageTagsList] = React.useState([])
  const [imageTagsAction, setImageTagsAction] = React.useState('')
  const [filterBYInputValue, setfilterByInputValue] = React.useState('')
  const [confirmationDailog, setConfirmationDailog] = React.useState(false)
  const [sorting, setSorting] = React.useState({})
  const [confirmationDailogSection, setConfirmationDailogSection] =
    React.useState('')

  const [editTagRows, setEditTagRows] = React.useState({})
  const [qaNotes, setQaNotes] = React.useState('')
  const [notesAction, setNotesAction] = React.useState('')
  const [notes, setNotes] = React.useState('')
  const [originalQaNotes, setOriginalQaNotes] = React.useState('')
  const [originalNotes, setOriginalNotes] = React.useState('')
  const [tagDetails, settagDetails] = React.useState({
    tag_name: '',
    tag_description: '',
  })

  // const [userSelectedFilter, setFilter] = React.useState({
  //   [tableTab]: mapValues(filterBy, (o) => o.userSelected),
  // })

  const [addFilterDropDown, setAddFilterDropDown] = React.useState(false)

  const [downloadSheetAnchorEl, setDownloadSheetAnchorEl] = React.useState(null)

  const filtersValue = userSelectedFilter[tableTab] || {}
  const subChipfiltersValue = subChipFilter[tableTab] || []
  const selectedFiltersValue = selectedFilters[tableTab] || []
  const tabWiseSelection = React.useMemo(() => {
    return selection[tableTab] || []
  }, [selection, tableTab])

  const [assigneeInputValue, setAssigneeInputValue] = React.useState('')
  const [qaAssigneeInputValue, setQaAssigneeInputValue] = React.useState('')
  const imageStatusList =
    memberOf?.includes(adminADGroup.toUpperCase()) ||
    memberOf?.includes(retouchingLeadADGroup.toUpperCase()) ||
    memberOf?.includes(producerADGroup.toUpperCase())
      ? rolewiseImageStatusList.others
      : memberOf?.includes(retouchingQAADGroup.toUpperCase())
        ? rolewiseImageStatusList.app_ptb_reTouching_qa
        : memberOf?.includes(retoucherADGroup.toUpperCase())
          ? rolewiseImageStatusList.aap_ptb_retoucher
          : memberOf?.includes(artDirectorADGroup.toUpperCase())
            ? rolewiseImageStatusList.app_ptb_artDirectors
            : []

  const [unapprovedImages, filteredReusedSelection, reusedSelection] =
    tabWiseSelection &&
    tabWiseSelection.reduce(
      (acc, x) => {
        if (x.imageStatus !== 'Approved') {
          acc[0] = acc[0] + 1
        }
        if (!x.isMultiTcin || x.isMultiTcin === x.isOriginal) {
          acc[1].push(x)
        } else {
          acc[2].push(x)
        }
        return acc
      },
      [0, [], []]
    )
  const channel = new BroadcastChannel('selectionChannel')

  React.useImperativeHandle(ref, () => ({
    showDrawer() {
      setOpenDrawer('Manage Tags')
    },
    showWorkflowDrawer(tab, selection, origin = '') {
      setOpenDrawer('Workflow')
      setSelection((prev) => ({ ...prev, [tab]: selection }))
      setOpenDrawerOrigin(origin)
    },
    search() {
      const { orderBy = '', order = '' } = sorting
      setPage(0)
      customTableRef.current.setSelect([], 'SEARCH')
      setPageSize(50)
      deleteFilters('SEARCH')
      dispatch(setUserFilter({}, 'SEARCH'))
      dispatch({
        type: 'GET_CUSTOM_COLUMNS_FULFILLED',
        payload: generateDefaultColumnVisibilityModel('SEARCH'),
      })
      dispatch(
        getWorkflowDataAction(
          lanId,
          accessToken,
          'SEARCH',
          0,
          50,
          searchType === 'Images'
            ? {
                image_names: [...new Set(compact(searchedValue.split(/, |,/)))],
              }
            : {
                keywords: [
                  ...new Set(compact(searchKeywordValue.split(/, |,/))),
                ],
              },
          orderBy,
          order
        )
      )
    },
    updateSelectionForUpload() {
      if (
        tabWiseSelection.length &&
        tabWiseSelection.some((data) => uploadsInProgress[data.fileName])
      ) {
        const filteredSelection = tabWiseSelection.filter(
          (d) => !uploadsInProgress[d.fileName]
        )
        customTableRef.current.setSelect(filteredSelection)
      }
    },
    updateSelection(updatedData) {
      const updatedSelection = cloneDeep(tabWiseSelection).map((d) => {
        let {
          file_name = '',
          image_url = '',
          primary_image_url = '',
          thumbnail_url = '',
          uploaded_version_number: uploaded_version = '',
          zoom_url = '',
          file_size = '',
        } = updatedData
        if (d.fileName === file_name) {
          return {
            ...d,
            previewImage: thumbnail_url,
            originalImageUrl: image_url,
            supportImageUrl: primary_image_url,
            fileSize: file_size,
            zoomImage: zoom_url,
            uploaded_version,
          }
        }
        return d
      })
      customTableRef.current.setSelect(updatedSelection)
    },
  }))

  const closeDownloadSheet = () => {
    setDownloadSheetAnchorEl(null)
  }

  const openDownloadSheet = (event) => {
    setDownloadSheetAnchorEl(event.currentTarget)
  }

  const openAdvancedPreview = () => {
    const workflowTableProps = {
      tableTab: tableTab,
    }
    if (!openedWindow.current || openedWindow.current.closed) {
      localStorage.setItem(
        'workflowTableProps',
        JSON.stringify(workflowTableProps)
      )
      localStorage.setItem('selectionState', JSON.stringify(tabWiseSelection))
      localStorage.setItem(
        'handleImageStatusChange',
        handleImageStatusChange?.toString()
      )
      openedWindow.current = window.open(
        `${window.location.origin}/advancedPreview`,
        '_blank'
      )
    } else {
      const message = {
        selection: JSON.stringify(tabWiseSelection),
        workflowTableProps: JSON.stringify(workflowTableProps),
        handleImageStatusChange: handleImageStatusChange?.toString(),
      }
      channel.postMessage(message)
      openedWindow.current.focus()
    }
  }

  const handleTagsChange = (value) => {
    const addedTags = cloneDeep(imageTagsList).map((dat) => ({
      ...dat,
      tag_ids: [...dat.tag_ids, value],
    }))
    setImageTagsList(addedTags)
  }

  const handleImageStatusChange = (event) => {
    const {
      target: { value },
    } = event
    setImageWorkflow((prev) => ({
      ...prev,
      image_status: typeof value === 'string' ? value.split(',') : value,
    }))
  }

  const closeCustomizeColumns = () => {
    setCustomizeColumnsIsOpen(false)
  }

  const saveCustomColumns = async () => {
    if (columnView === 'default') {
      dispatch({
        type: 'GET_CUSTOM_COLUMNS_FULFILLED',
        payload: {
          tableTab,
          columnVisibilityModel: generateDefaultColumnVisibilityModel(tableTab),
          column_list,
        },
      })
    } else {
      dispatch(
        updateWorkflowCustomColumnsAction(
          accessToken,
          lanId,
          tableTab,
          columnSelectionModel[tableTab]
        )
      )
    }
    closeCustomizeColumns()
  }

  const handleColumnViewChange = (event) => {
    if (event.target.value === 'default') {
      dispatch({
        type: 'SET_CUSTOM_COLUMNS_CHANGED',
        payload: {
          tableTab,
          columnSelectionModel: generateDefaultColumnVisibilityModel(tableTab),
          column_list,
        },
      })
    } else if (event.target.value === 'custom') {
      dispatch({
        type: 'SET_CUSTOM_COLUMNS_CHANGED',
        payload: {
          tableTab,
          columnSelectionModel: generateCustomColumnVisibilityModel(
            column_list,
            tableTab
          ),
          column_list,
        },
      })
    }
    setColumnView(event.target.value)
  }
  const handleDownloadSupport = async (filesList) => {
    const { getSupportImagesApi = '' } = apiConfig
    const payload = filesList.map((data) => ({
      file_name: data.fileName,
      version: data.originalVersion,
      uploaded_version: data.uploaded_version,
    }))
    try {
      const response = await axios.post(
        getSupportImagesApi,
        {
          image_details: payload,
        },
        {
          params: {
            key: apiConfig.apiKey,
          },
        }
      )
      const { primary_and_support_image_details = [] } = response.data

      let supportAndPrimaryImages = primary_and_support_image_details.reduce(
        (acc, current) => {
          acc.push(...current.support_images, {
            fileName: current.primary_image_name,
            originalImageUrl: current.primary_image_url,
          })

          return acc
        },
        []
      )
      const filesToDownload = supportAndPrimaryImages.reduce((acc, item) => {
        const id = item.fileName
          ? item.fileName.split('.')[0]
          : item.support_image_name
            ? item.support_image_name.split('.')[0]
            : ''
        acc[id] = {
          fileName: item.fileName || item.support_image_name,
          url: item.originalImageUrl || item.support_image_url,
          status: 'Not Started',
          timeStamp: Date.now(),
        }
        return acc
      }, {})
      dispatch(setFilesForDownloads(filesToDownload))
    } catch (error) {
      dispatch(
        showNotification(
          true,
          'Error while getting support images',
          'error',
          1000
        )
      )
    }
  }

  const handleCustomizeColumnChange = (event) => {
    let newColumnSelectionModel = cloneDeep(columnSelectionModel)
    newColumnSelectionModel[tableTab][event.target.name].selected =
      event.target.checked
    dispatch({
      type: 'SET_CUSTOM_COLUMNS_CHANGED',
      payload: {
        tableTab,
        columnSelectionModel: newColumnSelectionModel[tableTab],
        column_list,
      },
    })
  }

  const handleDownload = () => {
    let csvHeaders = []
    csvHeaders = [
      ...columns,
      {
        headerName: 'Props',
        id: 'tcin_props',
      },
    ]
      .filter((column) => !column.notIncludeInCsv)
      .map((column) => {
        return {
          key: column.id,
          label: column.headerName,
        }
      })
    setCsvData({
      headers: csvHeaders,
      data: workflowCsvMapper(tabWiseSelection),
    })
  }

  const onFilterDrawerClose = () => {
    if (!isLoading) {
      dispatch(
        setSelectedFilter(
          Object.keys(filterBy).filter((key) =>
            Object.keys(activeUserSelectedFilter[tableTab] || {}).includes(key)
          ),
          tableTab
        )
      )
      dispatch(
        setActiveUserFilter(activeUserSelectedFilter[tableTab], tableTab)
      )
    }
  }

  React.useEffect(() => {
    if (
      !isEmpty(csvData.data) &&
      csvBtn.current !== null &&
      csvBtn.current.link !== null
    ) {
      setTimeout(() => {
        csvBtn.current !== null && csvBtn.current.link.click()
        setCsvData({ ...csvData, data: false })
      })
    }
  }, [csvData])

  React.useEffect(() => {
    if (openDrawer === 'Manage Tags') {
      dispatch(getManageTagsAction(accessToken))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer])

  React.useEffect(() => {
    if (tabWiseSelection.length === 1) {
      const {
        notes = '',
        qaNotes = '',
        tags = [],
        fileName = '',
        originalVersion = '',
      } = tabWiseSelection[0]
      setQaNotes(qaNotes || '')
      setNotes(notes || '')
      setOriginalQaNotes(qaNotes || '')
      setOriginalNotes(notes || '')
      const tagIds = tags.length ? tags.map((e) => e.tagId) : []
      setImageTagsList([
        {
          file_name: fileName,
          version: originalVersion,
          tag_ids: tagIds,
        },
      ])
    } else {
      let editedTags = filteredReusedSelection.map((item) => {
        const { tags = [], fileName = '', originalVersion = '' } = item
        let newTags = []
        if (tags) {
          newTags = tags.map((e) => e.tagId)
        }
        return {
          file_name: fileName,
          version: originalVersion,
          tag_ids: newTags,
        }
      })
      editedTags && setImageTagsList(editedTags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer, tabWiseSelection])

  const getSelectedTagsList = () => {
    return imageTagsList.reduce((acc, item) => {
      const { tag_ids = [] } = item
      if (tag_ids) {
        acc = [...acc, ...tag_ids]
      }
      return [...new Set(acc)]
    }, [])
  }

  const setImageTags = async (type) => {
    if (type === 'add') {
      setImageTagsAction('add')

      dispatch(
        changeImageTagsAction(
          accessToken,
          { image_tag_details: imageTagsList },
          lanId,
          (updatedValues) => {
            const updatedSelection = cloneDeep(tabWiseSelection).map((d) => ({
              ...d,
              tags: find(
                updatedValues,
                (dat) => dat.file_name === d.fileName
              ).tags.map((tag) => {
                return {
                  tagId: tag.tag_id,
                  tagName: tag.tag_name,
                  tagDesc: tag.tag_description,
                }
              }),
            }))
            customTableRef.current.setSelect(updatedSelection)
            setOpenDrawer('')
          }
        )
      )
    } else {
      setImageTagsAction('remove')
      const fileNames = filteredReusedSelection.map((data) => {
        const { fileName = '', originalVersion = '' } = data
        return {
          file_name: fileName,
          version: originalVersion,
          tag_ids: [],
        }
      })
      dispatch(
        changeImageTagsAction(
          accessToken,
          { image_tag_details: fileNames },
          lanId,
          () => {
            const updatedSelection = cloneDeep(tabWiseSelection).map((d) => ({
              ...d,
              tags: [],
            }))
            customTableRef.current.setSelect(updatedSelection)
            setOpenDrawer('')
            setImageTagsList([])
          }
        )
      )
    }
  }

  const chooseFilter = (event, newValue) => {
    dispatch(setSelectedFilter(compact(uniq(newValue)), tableTab))
    // Object.entries(filtersValue).forEach(([key, value]) => {
    //   if (key !== 'image_statuses' && !newValue.includes(key)) {
    //     const selectedFilter = cloneDeep(userSelectedFilter)
    //     delete selectedFilter[tableTab][key]
    //     setFilter(selectedFilter)
    //   }
    // })
  }

  const addStatusFilter = (statusName) => {
    let subFilterSelected = cloneDeep(filtersValue)
    if (subFilterSelected['image_statuses']) {
      if (filtersValue['image_statuses'].includes(statusName)) {
        subFilterSelected['image_statuses'] = remove(
          subFilterSelected.image_statuses,
          (status) => status !== statusName
        )
      } else {
        subFilterSelected['image_statuses'] = [
          ...subFilterSelected.image_statuses,
          statusName,
        ]
      }
    } else {
      subFilterSelected['image_statuses'] = [statusName]
    }
    dispatch(setActiveUserFilter(subFilterSelected, tableTab))
  }

  const addAssigneeFilter = (assignee) => {
    let subFilterSelected = cloneDeep(filtersValue)
    if (subFilterSelected['assignees']) {
      if (filtersValue['assignees'].includes(assignee)) {
        subFilterSelected['assignees'] = remove(
          subFilterSelected.assignees,
          (name) => name !== assignee
        )
      } else {
        if (assignee === 'Unassigned') {
          subFilterSelected['assignees'] = [assignee]
        } else {
          subFilterSelected['assignees'] = [
            ...subFilterSelected.assignees.filter((d) => d !== 'Unassigned'),
            assignee,
          ]
        }
      }
    } else {
      subFilterSelected['assignees'] = [assignee]
    }
    dispatch(setActiveUserFilter(subFilterSelected, tableTab))
  }

  const addQAAssigneeFilter = (assignee) => {
    let subFilterSelected = cloneDeep(filtersValue)
    if (subFilterSelected['qa_assignees']) {
      if (filtersValue['qa_assignees'].includes(assignee)) {
        subFilterSelected['qa_assignees'] = remove(
          subFilterSelected.qa_assignees,
          (name) => name !== assignee
        )
      } else {
        subFilterSelected['qa_assignees'] = [
          ...subFilterSelected.qa_assignees,
          assignee,
        ]
      }
    } else {
      subFilterSelected['qa_assignees'] = [assignee]
    }
    dispatch(setActiveUserFilter(subFilterSelected, tableTab))
  }

  const handleStartDateChange = (inputDate, filterKey) => {
    inputDate.setHours(0, 0, 0, 0)
    let subFilterSelected = cloneDeep(filtersValue)
    if (subFilterSelected[filterKey]) {
      let endDate = filtersValue[filterKey]
        .filter((date) => !isNaN(Date.parse(date)))
        .map((date) => new Date(date))
        .sort((a, b) => a - b)[1]
      subFilterSelected[filterKey] = compact([
        formatDateTime(inputDate, 'YYYY-MM-DD'),
        ...Object.keys(filterBy[filterKey].values).filter(
          (date) => new Date(date) >= inputDate
        ),
        formatDateTime(endDate, 'YYYY-MM-DD'),
      ])
    } else {
      subFilterSelected[filterKey] = compact([
        formatDateTime(inputDate, 'YYYY-MM-DD'),
        ...Object.keys(filterBy[filterKey].values).filter(
          (date) => new Date(date) >= inputDate
        ),
        formatDateTime(inputDate, 'YYYY-MM-DD'),
      ])
    }
    dispatch(setActiveUserFilter(subFilterSelected, tableTab))
  }

  const handleEndDateChange = (inputDate, filterKey) => {
    inputDate.setHours(0, 0, 0, 0)
    let nextDay = new Date(inputDate)
    let startDate = filtersValue[filterKey]
      .filter((date) => !isNaN(Date.parse(date)))
      .map((date) => new Date(date))
      .sort((a, b) => a - b)[0]
    nextDay.setDate(nextDay.getDate() + 1)
    let subFilterSelected = cloneDeep(filtersValue)
    subFilterSelected[filterKey] = compact([
      formatDateTime(startDate, 'YYYY-MM-DD'),
      ...Object.keys(filterBy[filterKey].values).filter(
        (date) => new Date(date) < nextDay && new Date(date) >= startDate
      ),
      formatDateTime(inputDate, 'YYYY-MM-DD'),
    ])
    dispatch(setActiveUserFilter(subFilterSelected, tableTab))
  }

  const handleChange = (e, newValue, s) => {
    let subFilterSelected = cloneDeep(filtersValue)
    if (subFilterSelected[s]) {
      subFilterSelected[s] = newValue
    } else {
      subFilterSelected[s] = newValue
    }
    dispatch(setActiveUserFilter(subFilterSelected, tableTab))
  }

  const deleteFilters = (tab = tableTab) => {
    let subFilterSelected = cloneDeep(filtersValue)
    if (!isEmpty(subFilterSelected)) {
      Object.entries(subFilterSelected || {}).forEach(([key]) => {
        if (subFilterSelected[key]) {
          delete subFilterSelected[key]
        }
      })
      dispatch(setActiveUserFilter(subFilterSelected, tab))
      dispatch(setSelectedFilter([], tab))
    }
  }

  const onTableSelection = (selected, tab) => {
    if (selected.length) {
      if (selected.length === 1) {
        const {
          notes = '',
          qaNotes = '',
          tags = [],
          fileName = '',
          originalVersion = '',
        } = selected[0]
        setQaNotes(qaNotes || '')
        setNotes(notes || '')

        const tagIds = tags.length ? tags.map((e) => e.tagId) : []
        setImageTagsList([
          {
            file_name: fileName,
            version: originalVersion,
            tag_ids: tagIds,
          },
        ])
      } else {
        setQaNotes('')
        setNotes('')
        let editedTags = filteredReusedSelection.map((item) => {
          const { tags = [], fileName = '', originalVersion = '' } = item
          let newTags = []
          if (tags) {
            newTags = tags.map((e) => e.tagId)
          }
          return {
            file_name: fileName,
            version: originalVersion,
            tag_ids: newTags,
          }
        })
        setImageTagsList(editedTags)
      }
    }
    setSelection((prev) => ({ ...prev, [tab]: selected }))
  }

  const filterWorkflowTable = (
    tabName = tableTab,
    payload = {},
    callBack = () => {}
  ) => {
    const { orderBy = '', order = '' } = sorting
    dispatch(
      getWorkflowDataAction(
        lanId,
        accessToken,
        tabName,
        page,
        pageSize,
        payload,
        orderBy,
        order,
        callBack
      )
    )
  }

  const removeFilters = (page = 0, pageSize = 0) => {
    customTableRef.current.setSelect([])
    const { orderBy = '', order = '' } = sorting
    let subFilterSelected = cloneDeep(filtersValue)
    if (!isEmpty(subFilterSelected)) {
      Object.entries(subFilterSelected || {}).forEach(([key]) => {
        if (subFilterSelected[key]) {
          delete subFilterSelected[key]
        }
      })
      dispatch(setActiveUserFilter(subFilterSelected, tableTab))
      dispatch(setSelectedFilter([], tableTab))
      if (searchedValue) {
        filterWorkflowTable(
          'SEARCH',
          {
            image_names: [...new Set(compact(searchedValue.split(/, |,/)))],
          },
          () => {
            dispatch(setUserFilter({}, tableTab))
            dispatch(setSubFilter([], tableTab))
          }
        )
      } else {
        filterWorkflowTable(
          tableTab,
          {
            ...(studioList.length && {
              photo_studios: [...studioList],
            }),
          },
          () => {
            setOpenDrawer('')
            dispatch(setUserFilter({}, tableTab))
            dispatch(setSubFilter([], tableTab))
          }
        )
      }
    } else {
      setOpenDrawer('')
    }
  }

  const handleConfirmationChange = () => {
    switch (confirmationDailogSection) {
      case 'QA Notes':
        addNotes('qaNotes', 'add')
        break
      case 'Notes':
        addNotes('notes', 'add')
        break
      default:
        setConfirmationDailog(false)
        break
    }
  }

  const handleFilterChipsClick = (option = '', page = 0, pageSize = 0) => {
    const { orderBy = '', order = '' } = sorting
    customTableRef.current.setSelect([])
    const filters = cloneDeep(subChipFilter[tableTab])
    if (filters.includes(option)) {
      const activeFilters = filters.filter((d) => d !== option)
      const filteredImageStatus = activeFilters.filter(
        (data) =>
          data !== 'Assigned To Me' &&
          data !== 'Unassigned' &&
          data !== 'QA Assigned To Me'
      )
      if (
        option === 'Assigned To Me' ||
        option === 'QA Assigned To Me' ||
        option === 'Unassigned'
      ) {
        let payload = {
          ...activeUserSelectedFilter[tableTab],
          ...(filteredImageStatus.length && {
            image_statuses: filteredImageStatus,
          }),
          ...(studioList.length && {
            photo_studios: [
              ...(activeUserSelectedFilter[tableTab]?.photo_studios || []),
              ...studioList,
            ],
          }),
        }
        let appliedFiltered = {
          ...activeUserSelectedFilter[tableTab],
        }
        switch (option) {
          case 'Assigned To Me':
            payload = {
              ...payload,
              assignees: [
                ...(activeUserSelectedFilter[tableTab]?.assignees || []).filter(
                  (d) => d !== displayname
                ),
              ],
            }
            appliedFiltered = {
              ...appliedFiltered,
              assignees: appliedFiltered?.assignees.filter(
                (d) => d !== displayname
              ),
            }

            break
          case 'QA Assigned To Me':
            payload = {
              ...payload,
              qa_assignees: [
                ...(activeUserSelectedFilter[tableTab]?.qa_assignees || []),
                displayname,
              ],
            }
            appliedFiltered = {
              ...appliedFiltered,
              qa_assignees: appliedFiltered.qa_assignees.filter(
                (d) => d !== displayname
              ),
            }

            break
          case 'Unassigned':
            payload = {
              ...payload,
              assignees: appliedFiltered.assignees.filter(
                (d) => d !== 'Unassigned'
              ),
            }
            appliedFiltered = {
              ...appliedFiltered,
              assignees: appliedFiltered.assignees.filter(
                (d) => d !== 'Unassigned'
              ),
            }
            break
          default:
            break
        }
        filterWorkflowTable(tableTab, payload, () => {
          dispatch(setSubFilter(activeFilters, tableTab))
          option === 'QA Assigned To Me'
            ? addQAAssigneeFilter('')
            : addAssigneeFilter('')
          dispatch(setUserFilter(appliedFiltered, tableTab))
        })
      } else {
        const payload = {
          ...activeUserSelectedFilter[tableTab],
          image_statuses: filteredImageStatus,
          ...(subChipfiltersValue.includes('Assigned To Me') && {
            assignees: [displayname],
          }),
          ...(studioList.length && {
            photo_studios: [
              ...(activeUserSelectedFilter[tableTab]?.photo_studios || []),
              ...studioList,
            ],
          }),
          ...(subChipfiltersValue.includes('Unassigned') && {
            assignees: ['Unassigned'],
          }),
        }
        Object.keys(payload).forEach(
          (k) => !payload[k].length && delete payload[k]
        )
        filterWorkflowTable(tableTab, payload, () => {
          dispatch(setSubFilter(activeFilters, tableTab))
          addStatusFilter(option)
          dispatch(setUserFilter(payload, tableTab))
        })
      }
    } else {
      const filteredStatus = subChipFilter[tableTab].filter(
        (data) =>
          data !== 'Assigned To Me' &&
          data !== 'QA Assigned To Me' &&
          data !== 'Unassigned'
      )
      if (
        option === 'Assigned To Me' ||
        option === 'QA Assigned To Me' ||
        option === 'Unassigned'
      ) {
        const filteredAssignee = (
          activeUserSelectedFilter[tableTab]?.assignees || []
        ).filter((data) => data !== 'Unassigned')
        const filteredChip = subChipFilter[tableTab].filter(
          (data) => data !== 'Unassigned'
        )

        let payload = {
          ...activeUserSelectedFilter[tableTab],
          ...(filteredStatus.length && { image_statuses: filteredStatus }),
          ...(studioList.length && {
            photo_studios: [
              ...(activeUserSelectedFilter[tableTab]?.photo_studios || []),
              ...studioList,
            ],
          }),
        }
        let appliedFiltered = {
          ...activeUserSelectedFilter[tableTab],
          ...(filteredStatus.length && {
            image_statuses: filteredStatus,
          }),
        }
        switch (option) {
          case 'Assigned To Me':
            payload = {
              ...payload,
              assignees: [
                ...(activeUserSelectedFilter[tableTab]?.assignees || []).filter(
                  (d) => d !== 'Unassigned'
                ),
                displayname,
              ],
            }
            appliedFiltered = {
              ...appliedFiltered,
              assignees: [...filteredAssignee, displayname],
            }
            break
          case 'QA Assigned To Me':
            payload = {
              ...payload,
              qa_assignees: [
                ...(activeUserSelectedFilter[tableTab]?.qa_assignees || []),
                displayname,
              ],
            }
            appliedFiltered = {
              ...appliedFiltered,
              qa_assignees: [
                ...(activeUserSelectedFilter[tableTab]?.qa_assignees || []),
                displayname,
              ],
            }
            break
          case 'Unassigned':
            payload = {
              ...payload,
              assignees: [option],
            }
            appliedFiltered = {
              ...appliedFiltered,
              assignees: [option],
            }
            break
          default:
            break
        }
        filterWorkflowTable(tableTab, payload, () => {
          option === 'Assigned To Me'
            ? dispatch(setSubFilter([...filteredChip, option], tableTab))
            : dispatch(setSubFilter([...subChipfiltersValue, option], tableTab))
          option === 'QA Assigned To Me'
            ? addQAAssigneeFilter(displayname)
            : option === 'Unassigned'
              ? addAssigneeFilter('Unassigned')
              : addAssigneeFilter(displayname)
          dispatch(setUserFilter(appliedFiltered, tableTab))
        })
      } else {
        filterWorkflowTable(
          tableTab,
          {
            ...activeUserSelectedFilter[tableTab],
            ...(subChipfiltersValue.includes('Assigned To Me') && {
              assignees: [displayname],
            }),
            ...(studioList.length && {
              photo_studios: [
                ...(activeUserSelectedFilter[tableTab]?.photo_studios || []),
                ...studioList,
              ],
            }),
            ...(subChipfiltersValue.includes('Unassigned') && {
              assignees: ['Unassigned'],
            }),
            image_statuses: [...filteredStatus, option],
          },
          () => {
            dispatch(setSubFilter([...subChipfiltersValue, option], tableTab))
            addStatusFilter(option)
            dispatch(
              setUserFilter(
                {
                  ...activeUserSelectedFilter[tableTab],
                  image_statuses: [...filteredStatus, option],
                },
                tableTab
              )
            )
          }
        )
      }
    }
  }
  const generateToggleButtons = () => {
    const filtersApplied = omitBy(
      cloneDeep(activeUserSelectedFilter[tableTab]),
      isEmpty
    )
    return (
      <Stack direction="row" spacing={1}>
        <Badge
          badgeContent={
            (filtersApplied && Object.keys(filtersApplied).length) || 0
          }
          color="primary"
        >
          <Chip
            icon={<TuneIcon color="#366CD9" />}
            classes={{
              label: classes.chipsText,
            }}
            className={classes.moreFilters}
            clickable
            disabled={isLoading}
            onClick={() => setOpenDrawer('Filter By')}
            label="Filters"
            color="primary"
            variant="outlined"
          />
        </Badge>
        {tableTab !== 'SEARCH' && (
          <>
            {Object.keys(subTabCount).map((option) => {
              return tableTab !== 'RETOUCH' && option === 'Unassigned' ? (
                <></>
              ) : (
                <Chip
                  disabled={isLoading}
                  classes={{
                    root: classes.activeChips,
                    outlined: classes.outlinedChips,
                    label: classes.chipsText,
                  }}
                  avatar={
                    subChipfiltersValue.includes(option) && (
                      <DoneIcon style={{ background: 'transparent' }} />
                    )
                  }
                  onClick={async () => {
                    await setPage(0)
                    await setPageSize(50)
                    handleFilterChipsClick(option, 0, 50)
                  }}
                  label={`${option} (${subTabCount[option] || 0})`}
                  {...(!subChipfiltersValue.includes(option) && {
                    variant: 'outlined',
                  })}
                />
              )
            })}
          </>
        )}
        {!isLoading && (
          <Button
            variant="text"
            className={classes.clearButton}
            classes={{
              disabled: classes.dissableButton,
            }}
            disabled={isLoading || isEmpty(activeUserSelectedFilter[tableTab])}
            onClick={async () => {
              await setPage(0)
              await setPageSize(50)
              removeFilters(0, 50)
            }}
          >
            Clear Filters
          </Button>
        )}
      </Stack>
    )
  }

  const setImageToArchiveAction = async (fileDetails, isArchived) => {
    const { file_name = '' } = fileDetails
    dispatch(
      setImageToArchive(
        accessToken,
        {
          file_details: [fileDetails],
        },
        lanId,
        tableTab,
        page,
        pageSize,
        activeUserSelectedFilter[tableTab],
        isArchived,
        searchedValue,
        () => {
          const updatedSelection = cloneDeep(tabWiseSelection).filter((d) => {
            return d.fileName !== file_name
          })
          customTableRef.current.setSelect(updatedSelection)
        }
      )
    )
  }

  const changeWorkflowStatus = async () => {
    const { orderBy = '', order = '' } = sorting
    const fileNames = filteredReusedSelection.map((data) => {
      return {
        file_name: data.fileName,
        version: data.originalVersion,
        uploaded_version: data.uploaded_version,
      }
    })
    const imageWorkflowObj = cloneDeep(imageWorkflow)
    Object.entries(imageWorkflowObj || {}).forEach(([key]) => {
      if (imageWorkflowObj[key]) {
        if (key === 'qa_assignee') {
          delete imageWorkflowObj[key]?.label
        } else if (key === 'assignee') {
          delete imageWorkflowObj[key]?.label
        } else if (key === 'image_status') {
          imageWorkflowObj[key] = imageWorkflowObj[key][0]
        } else {
          delete imageWorkflowObj[key]
        }
      } else {
        delete imageWorkflowObj[key]
      }
    })
    dispatch(
      changeImageWorkflowAction(
        accessToken,
        {
          file_details: fileNames,
          ...imageWorkflowObj,
        },
        lanId,
        tableTab,
        page,
        pageSize,
        activeUserSelectedFilter[tableTab],
        () => {
          setImageWorkflow({})
          setOpenDrawer('')
          setQaAssigneeInputValue('')
          setAssigneeInputValue('')
          if (imageWorkflowObj.image_status) {
            customTableRef.current.setSelect([])
          }
        },
        searchedValue,
        studioList,
        order,
        orderBy
      )
    )
  }

  const sendToPipeline = async () => {
    const fileNames = filteredReusedSelection.map((data) => {
      return {
        file_name: data.fileName,
        ingested_version: data.originalVersion,
        uploaded_version: data.uploaded_version,
      }
    })

    dispatch(
      sendToPipelineAction(
        accessToken,
        { image_details: fileNames },
        lanId,
        tableTab,
        page,
        pageSize,
        {
          ...activeUserSelectedFilter[tableTab],
          ...(searchedValue && {
            image_names: [...new Set(compact(searchedValue.split(/, |,/)))],
          }),
        },
        () => {
          setOpenDrawer('')
          customTableRef.current.setSelect([])
        }
      )
    )
  }

  const handleRetouchContactSheets = async (filesList) => {
    const payload = filesList.map((data) => ({
      file_name: data.fileName,
      version: data.originalVersion,
      uploaded_version: data.uploaded_version,
    }))
    try {
      setContactSheetsDownloading(true)
      const response = await generateRetouchContactSheet(accessToken, payload)
      const { data = {} } = await response
      var file = new Blob([data], {
        type: response.headers['content-type'],
      })
      setContactSheetsDownloading(false)
      var fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } catch (err) {
      setContactSheetsDownloading(false)
      dispatch(
        showNotification(
          true,
          `Error while downloading Contact Sheets`,
          'error',
          1000
        )
      )
    }
  }

  const exportFinalJpegs = async (filesList) => {
    filesList.forEach((data, i) => {
      if (data.zoomImage) downloadPreviewImage(data.zoomImage)
    })
  }

  const addNotes = async (type = '', action = '') => {
    setConfirmationDailog(false)
    if (action === 'clear') {
      type === 'notes' ? setNotes('') : setQaNotes('')
    } else {
      setNotesAction(action)
      const fileNames = filteredReusedSelection.map((data) => {
        return {
          file_name: data.fileName,
          version: data.originalVersion,
          uploaded_version: data.uploaded_version,
        }
      })
      const payload = { file_details: fileNames }
      type === 'notes'
        ? (payload['notes'] = notes)
        : (payload['qa_notes'] = qaNotes)
      dispatch(
        type === 'notes'
          ? addNotesAction(accessToken, payload, lanId, () => {
              const updatedSelection = cloneDeep(tabWiseSelection).map((d) => ({
                ...d,
                notes: notes,
              }))
              customTableRef.current.setSelect(updatedSelection)
              setOpenDrawer('')
              setNotes('')
            })
          : addQANotesAction(accessToken, payload, lanId, () => {
              const updatedSelection = cloneDeep(tabWiseSelection).map((d) => ({
                ...d,
                qaNotes: qaNotes,
              }))
              customTableRef.current.setSelect(updatedSelection)
              setOpenDrawer('')
              setQaNotes('')
            })
      )
    }
  }

  const addTag = () => {
    dispatch(
      addTagsAction(accessToken, tagDetails, lanId, () => {
        settagDetails({
          tag_name: '',
          tag_description: '',
        })
      })
    )
  }

  const submitTagEdit = (id) => {
    dispatch(
      editTagsAction(accessToken, editTagRows[id], lanId, id, () => {
        const filterData = cloneDeep(editTagRows)
        delete filterData[id]
        setEditTagRows(filterData)
      })
    )
  }

  const setWorkflowState = async (
    pageNumber,
    rowsPerPage,
    appliedFilters = activeUserSelectedFilter[tableTab],
    topStudioList = studioList
  ) => {
    let orderBy, order
    if (tableTab === 'RETOUCH') {
      orderBy = 'launch_date'
      order = 'desc'
      customTableRef?.current?.setOrder('launch_date', 'desc')
    } else {
      orderBy = sorting.orderBy
      order = sorting.order
    }
    setPage(pageNumber)
    setPageSize(rowsPerPage)
    // deleteFilters()
    if (searchedValue) {
      dispatch(
        getWorkflowDataAction(
          lanId,
          accessToken,
          'SEARCH',
          pageNumber,
          rowsPerPage,
          {
            ...appliedFilters,
            image_names: [...new Set(compact(searchedValue.split(/, |,/)))],
          },
          orderBy,
          order
        )
      )
    } else {
      dispatch(
        getWorkflowDataAction(
          lanId,
          accessToken,
          tableTab,
          pageNumber,
          rowsPerPage,
          {
            ...appliedFilters,
            ...(topStudioList.length && {
              photo_studios: [
                ...(appliedFilters?.photo_studios || []),
                ...topStudioList,
              ],
            }),
          },
          orderBy,
          order
        )
      )
    }
  }

  const applyFilters = (page = 0, pageSize = 50) => {
    const { orderBy = '', order = '' } = sorting
    customTableRef.current.setSelect([])
    if (searchedValue) {
      dispatch(
        getWorkflowDataAction(
          lanId,
          accessToken,
          tableTab,
          page,
          pageSize,
          {
            ...filtersValue,
            image_names: [...new Set(compact(searchedValue.split(/, |,/)))],
          },
          orderBy,
          order,
          () => {
            setOpenDrawer('')
            dispatch(
              setUserFilter(
                {
                  ...filtersValue,
                  image_names: [
                    ...new Set(compact(searchedValue.split(/, |,/))),
                  ],
                },
                tableTab
              )
            )
          }
        )
      )
    } else {
      dispatch(
        getWorkflowDataAction(
          lanId,
          accessToken,
          tableTab,
          page,
          pageSize,
          {
            ...filtersValue,
            ...(studioList.length && {
              photo_studios: [
                ...(filtersValue?.photo_studios || []),
                ...studioList,
              ],
            }),
          },
          orderBy,
          order,
          () => {
            setOpenDrawer('')
            dispatch(setUserFilter(filtersValue, tableTab))
            dispatch(
              setSubFilter(
                [
                  ...(filtersValue.image_statuses || []),
                  ...(filtersValue.assignees || [])
                    .map((d) => (d === displayname ? 'Assigned To Me' : d))
                    .filter(
                      (data) =>
                        data === 'Unassigned' || data === 'Assigned To Me'
                    ),
                  ...(filtersValue.qa_assignees || [])
                    .map((d) => (d === displayname ? 'QA Assigned To Me' : d))
                    .filter((data) => data === 'QA Assigned To Me'),
                ],
                tableTab
              )
            )
          }
        )
      )
    }
  }

  const getToolbarIcon = (name) => {
    switch (name) {
      case 'Tags':
        return <ToggleOffIcon />

      case 'Notes':
        return <NoteAddIcon />

      case 'QA Notes':
        return <NoteAltIcon />

      case 'Workflow':
        return <AssignmentIcon />

      default:
        return <></>
    }
  }

  const reusedWarning = (size = 9) => {
    return reusedSelection.length ? (
      <Grid item xs={12} lg={size} md={size}>
        {filteredReusedSelection.length ? (
          <Alert severity="warning">
            Any changes made to the Multi TCIN image will be applied to all
            related reused images.
          </Alert>
        ) : (
          <Alert severity="warning">
            Please select some original images to do operations.
          </Alert>
        )}
      </Grid>
    ) : (
      <></>
    )
  }

  const renderDrawer = (drawer) => {
    switch (drawer) {
      case 'Workflow': {
        return (
          <WorkflowFlyoutContent
            reusedWarning={reusedWarning}
            imageWorkflow={imageWorkflow}
            handleImageStatusChange={handleImageStatusChange}
            MenuProps={MenuProps}
            imageStatusList={imageStatusList}
            setImageWorkflow={setImageWorkflow}
            assigneeInputValue={assigneeInputValue}
            setAssigneeInputValue={setAssigneeInputValue}
            qaAssigneeInputValue={qaAssigneeInputValue}
            setQaAssigneeInputValue={setQaAssigneeInputValue}
            qaAsigneeList={qaAsigneeList}
            isLoading={isLoading}
            filteredReusedSelection={filteredReusedSelection}
            changeWorkflowStatus={changeWorkflowStatus}
            unapprovedImages={unapprovedImages}
            tabWiseSelection={tabWiseSelection}
            sendToPipeline={sendToPipeline}
            classes={classes}
            asigneeList={asigneeList}
            reusedSelection={reusedSelection}
          />
        )
      }
      case 'Tags':
        return (
          <Grid container className={classes.drawerContainer}>
            <Grid item xs={9} lg={9} md={9}>
              <FormLabel className={classes.tagsLabel}>
                Apply tags to selected images
              </FormLabel>
              <div style={{ marginBottom: '24px' }}>{reusedWarning(12)}</div>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="tags-label">Add or Remove Tags</InputLabel>
                <Select
                  multiple
                  labelId="tags-label"
                  name="tagsSelect"
                  value={getSelectedTagsList()}
                  // onChange={handleTagsChange}
                  input={<OutlinedInput label="Add or Remove Tags" />}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                          const item = tagsList.find(
                            ({ tag_id: v }) => v === value
                          )
                          const { tag_name = '' } = item || {}
                          return (
                            <Chip
                              clickable
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                              onDelete={(e) => {
                                const addedTags = cloneDeep(imageTagsList).map(
                                  (dat) => ({
                                    ...dat,
                                    tag_ids: dat.tag_ids.filter(
                                      (e) => e !== value
                                    ),
                                  })
                                )
                                setImageTagsList(addedTags)
                              }}
                              key={value}
                              label={tag_name}
                            />
                          )
                        })}
                      </Box>
                    )
                  }}
                  MenuProps={MenuProps}
                >
                  {tagsList.map((data) => (
                    <MenuItem
                      key={data.tag_id}
                      value={data.tag_id}
                      onClick={() => handleTagsChange(data.tag_id)}
                    >
                      {data.tag_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid>
                <Stack
                  direction="row"
                  className={classes.buttonGroup}
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    disabled={isApplyingTags || !filteredReusedSelection.length}
                    className={classes.stausButton}
                    onClick={() => setImageTags('remove')}
                  >
                    {isApplyingTags && imageTagsAction === 'remove' ? (
                      <CircularProgress size={14} />
                    ) : (
                      'Remove'
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={
                      !imageTagsList.length ||
                      isApplyingTags ||
                      !filteredReusedSelection.length
                    }
                    onClick={() => setImageTags('add')}
                    className={`${classes.stausButton} ${classes.primaryButton}`}
                  >
                    {isApplyingTags && imageTagsAction === 'add' ? (
                      <CircularProgress size={14} />
                    ) : (
                      'Apply'
                    )}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )
      case 'Manage Tags': {
        const { tag_name: tagName, tag_description: tagDescription } =
          tagDetails
        return (
          <>
            <Divider className={classes.drawerHeaderDivider} />
            <Grid container className={classes.tagsDrawerContainer}>
              <Grid item xs={12} lg={12} md={12}>
                <Accordion
                  elevation={0}
                  square
                  expanded={createTagexpanded}
                  onChange={() => setCreateTagExpanded(!createTagexpanded)}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    classes={{
                      root: classes.accordianSummaryRoot,
                      content: classes.accordianSummaryContent,
                    }}
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Create Tag
                    </Typography>

                    <ExpandMoreIcon
                      className={`${classes.nonExpandedIcon} ${
                        createTagexpanded ? classes.expandedIcon : ''
                      }`}
                      style={{ marginLeft: '5px' }}
                    />
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <FormGroup row style={{ width: '100%' }}>
                      <FormControl
                        variant="outlined"
                        style={{
                          width: '30%',
                          maxWidth: '300px',
                          marginRight: '24px',
                        }}
                      >
                        <TextField
                          variant="outlined"
                          label="Enter tag name"
                          value={tagName}
                          disabled={isAddingTags}
                          onChange={(e) => {
                            settagDetails((state) => ({
                              ...state,
                              tag_name: e.target.value,
                            }))
                          }}
                          name="tagName"
                        />
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{
                          width: '40%',
                          maxWidth: '400px',
                          marginRight: '40px',
                        }}
                      >
                        <TextField
                          variant="outlined"
                          label="Enter tag description"
                          value={tagDescription}
                          disabled={isAddingTags}
                          onChange={(e) => {
                            settagDetails((state) => ({
                              ...state,
                              tag_description: e.target.value,
                            }))
                          }}
                          name="tagName"
                        />
                      </FormControl>
                      <Stack direction="row" spacing={2} alignItems={'center'}>
                        <Button
                          variant="outlined"
                          className={`${classes.stausButton} ${classes.inlineButton}`}
                          disabled={isAddingTags}
                          onClick={() => {
                            settagDetails({
                              tag_name: '',
                              tag_description: '',
                            })
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          disabled={
                            !tagName && (isAddingTags || isLoadingManageTags)
                          }
                          onClick={addTag}
                          className={`${classes.stausButton} ${classes.primaryButton} ${classes.inlineButton}`}
                        >
                          {isAddingTags || isLoadingManageTags ? (
                            <CircularProgress size={14} />
                          ) : (
                            'Add'
                          )}
                        </Button>
                      </Stack>
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Divider className={`${classes.drawerHeaderDivider}`} />
              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                style={{ height: ' calc(100vh - 250px' }}
              >
                <TableContainer
                  sx={{
                    width: 'calc(100% + 48px)',
                    marginLeft: '-24px',
                    maxHeight: '600px',
                    height: '100%',
                  }}
                >
                  <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className={`${classes.padding_right_null} ${classes.tagsHeaderColumn}`}
                          style={{
                            width: '20%',
                          }}
                        >
                          Tags
                        </TableCell>
                        <TableCell
                          align="left"
                          className={`${classes.padding_left_right_null} ${classes.tagsHeaderColumn}`}
                          style={{ width: '25%' }}
                        >
                          Tags Description
                        </TableCell>
                        <TableCell
                          align="left"
                          className={`${classes.padding_left_right_null} ${classes.tagsHeaderColumn}`}
                          style={{ width: '10%' }}
                        >
                          #Images
                        </TableCell>
                        <TableCell
                          align="left"
                          className={`${classes.padding_left_right_null} ${classes.tagsHeaderColumn}`}
                          style={{ width: '10%' }}
                        >
                          Modified Date
                        </TableCell>
                        <TableCell
                          align="left"
                          className={`${classes.padding_left_right_null} ${classes.tagsHeaderColumn}`}
                          style={{ width: '10%' }}
                        >
                          Modified By
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.padding_left_right_null} ${classes.tagsHeaderColumn}`}
                          style={{ width: '10%' }}
                        >
                          Use With Export Script
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.padding_left_right_null} ${classes.tagsHeaderColumn}`}
                          style={{ width: '5%' }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.padding_left_right_null} ${classes.tagsHeaderColumn}`}
                          style={{ width: '5%' }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isLoadingManageTags && manageTagsList.length ? (
                        manageTagsList.map((rowData, key) => {
                          const {
                            tag_id = '',
                            tag_name = '',
                            tag_description = '',
                            modified_date = '',
                            images_count = '',
                            created_by: { display_name = '' } = {},
                            modified_by = {},
                            tag_selected = false,
                          } = rowData
                          const {
                            tag_name: editTagName = '',
                            tag_description: editTagDescription = '',
                          } = editTagRows[tag_id] || {}
                          return Object.keys(editTagRows).includes(tag_id) ? (
                            <TableRow key={tag_id}>
                              <TableCell colSpan={7}>
                                <Grid container justify="space-between">
                                  <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    md={12}
                                    container
                                    direction="column"
                                    justify="space-between"
                                  >
                                    <FormLabel className={classes.tagsLabel}>
                                      Edit Tag
                                    </FormLabel>
                                    <FormControl variant="outlined" fullWidth>
                                      <TextField
                                        variant="outlined"
                                        label="Tag Name"
                                        value={editTagName}
                                        onChange={(e) => {
                                          const filterData =
                                            cloneDeep(editTagRows)
                                          filterData[tag_id] = {
                                            ...filterData[tag_id],
                                            tag_name: e.target.value,
                                          }
                                          setEditTagRows(filterData)
                                        }}
                                        name="tagName"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    md={12}
                                    container
                                    direction="column"
                                    justify="space-between"
                                  >
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      justifyContent="center"
                                    >
                                      <Typography
                                        className={`${classes.tagsBodyColumn} ${classes.paddingZero}`}
                                        padding="0"
                                      >
                                        #Images: <span>{images_count}</span>
                                      </Typography>
                                      <Typography
                                        className={`${classes.tagsBodyColumn} ${classes.paddingZero}`}
                                        padding="0"
                                      >
                                        Modified Date:{' '}
                                        {formatDate(modified_date)}
                                      </Typography>
                                    </Stack>

                                    <FormControl variant="outlined" fullWidth>
                                      <TextField
                                        variant="outlined"
                                        label="Tag Description"
                                        value={editTagDescription}
                                        onChange={(e) => {
                                          const filterData =
                                            cloneDeep(editTagRows)

                                          filterData[tag_id] = {
                                            ...filterData[tag_id],
                                            tag_description: e.target.value,
                                          }
                                          setEditTagRows(filterData)
                                        }}
                                        name="tagName"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    lg={2}
                                    md={12}
                                    container
                                    direction="column"
                                    justify="space-between"
                                  >
                                    <Typography
                                      className={`${classes.tagsBodyColumn} ${classes.paddingZero}`}
                                    >
                                      Modified By:{' '}
                                      {modified_by?.display_name
                                        ? modified_by?.display_name
                                        : display_name}
                                    </Typography>
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="center"
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <Button
                                        variant="outlined"
                                        className={`${classes.stausButton} ${classes.inlineButton}`}
                                        disabled={isEditingTags[tag_id]}
                                        onClick={() => {
                                          const filterData =
                                            cloneDeep(editTagRows)
                                          delete filterData[tag_id]
                                          setEditTagRows(filterData)
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="contained"
                                        disabled={isEditingTags[tag_id]}
                                        onClick={() => submitTagEdit(tag_id)}
                                        className={`${classes.stausButton} ${classes.primaryButton} ${classes.inlineButton}`}
                                      >
                                        {isEditingTags &&
                                        isEditingTags[tag_id] ? (
                                          <CircularProgress size={14} />
                                        ) : (
                                          'Save'
                                        )}
                                      </Button>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow key={tag_id}>
                              <TableCell
                                // className={classes.tagsBodyColumn}
                                align="left"
                                style={{
                                  fontWeight: '400',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  color: '#333333',
                                  padding: '10px 0 10px 24px',
                                }}
                              >
                                <Chip
                                  style={{
                                    maxWidth: '200px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                  label={tag_name}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tagsBodyColumn}
                                align="left"
                                style={{
                                  maxWidth: '250px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tag_description}
                              </TableCell>

                              <TableCell
                                className={classes.tagsBodyColumn}
                                align="left"
                              >
                                {images_count}
                              </TableCell>
                              <TableCell
                                className={classes.tagsBodyColumn}
                                align="left"
                              >
                                {' '}
                                {formatDate(modified_date)}
                              </TableCell>

                              <TableCell
                                className={classes.tagsBodyColumn}
                                align="left"
                              >
                                {' '}
                                {modified_by?.display_name
                                  ? modified_by?.display_name
                                  : display_name}
                              </TableCell>
                              <TableCell
                                style={{ textAlign: 'center' }}
                                className={classes.tagsBodyColumn}
                              >
                                <Checkbox
                                  icon={
                                    isEditingTags && isEditingTags[tag_id] ? (
                                      <CircularProgress size={14} />
                                    ) : (
                                      icon
                                    )
                                  }
                                  disabled={
                                    (isDeletingTags &&
                                      tagsDeleting.includes(tag_id)) ||
                                    isEditingTags[tag_id]
                                  }
                                  onChange={() => {
                                    dispatch(
                                      setUseWithScriptAction(
                                        accessToken,
                                        tag_id,
                                        {
                                          tag_name: tag_name,
                                          tag_description: tag_description,
                                          tag_selected: !tag_selected,
                                        },
                                        lanId
                                      )
                                    )
                                  }}
                                  checkedIcon={
                                    isEditingTags && isEditingTags[tag_id] ? (
                                      <CircularProgress size={14} />
                                    ) : (
                                      checkedIcon
                                    )
                                  }
                                  checked={tag_selected}
                                />
                              </TableCell>
                              <TableCell className={classes.tagsBodyColumn}>
                                <IconButton
                                  disabled={
                                    (isDeletingTags &&
                                      tagsDeleting.includes(tag_id)) ||
                                    isEditingTags[tag_id]
                                  }
                                  onClick={() => {
                                    setEditTagRows((state) => ({
                                      ...state,
                                      [tag_id]: { tag_name, tag_description },
                                    }))
                                  }}
                                >
                                  <CreateIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell className={classes.tagsBodyColumn}>
                                <IconButton
                                  disabled={
                                    (isDeletingTags &&
                                      tagsDeleting.includes(tag_id)) ||
                                    isEditingTags[tag_id]
                                  }
                                  onClick={() => {
                                    dispatch(
                                      deleteTagsAction(
                                        accessToken,
                                        [tag_id],
                                        lanId
                                      )
                                    )
                                  }}
                                >
                                  {isDeletingTags &&
                                  tagsDeleting.includes(tag_id) ? (
                                    <CircularProgress size={14} />
                                  ) : (
                                    <DeleteIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      ) : !isLoadingManageTags && !manageTagsList.length ? (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            No Tags Available
                          </TableCell>
                        </TableRow>
                      ) : (
                        [...Array(5)].map((k, i) => (
                          <TableRow key={i}>
                            <TableCell
                              key={i}
                              style={{ left: 0, paddingTop: 0 }}
                            >
                              <Skeleton />
                            </TableCell>
                            {[...Array(7)].map((i) => {
                              return (
                                <TableCell key={i}>
                                  <Skeleton />
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )
      }
      case 'Filter By': {
        // const filtersValue = userSelectedFilter[tableTab]
        return (
          <>
            <Stack
              spacing={1}
              flexWrap="wrap"
              direction="row"
              style={{
                margin: '10px 0',
                display: 'inline-flex',
                flexWrap: 'wrap',
                gap: '8px',
              }}
            >
              {tableTab !== 'SEARCH' && (
                <>
                  {tableTab !== 'SEARCH' &&
                    Object.keys(subTabCount).map((option) => {
                      const isSelected =
                        option === 'Assigned To Me'
                          ? filtersValue['assignees'] &&
                            filtersValue['assignees'].includes(displayname)
                          : option === 'QA Assigned To Me'
                            ? filtersValue['qa_assignees'] &&
                              filtersValue['qa_assignees'].includes(displayname)
                            : option === 'Unassigned'
                              ? filtersValue['assignees'] &&
                                filtersValue['assignees'].includes('Unassigned')
                              : filtersValue['image_statuses'] &&
                                filtersValue['image_statuses'].includes(option)

                      return tableTab !== 'RETOUCH' &&
                        option === 'Unassigned' ? (
                        <></>
                      ) : (
                        <Chip
                          classes={{
                            root: classes.activeChips,
                            outlined: classes.outlinedChips,
                          }}
                          style={{ marginLeft: '0' }}
                          avatar={
                            isSelected && (
                              <DoneIcon style={{ background: 'transparent' }} />
                            )
                          }
                          onClick={() => {
                            option === 'Assigned To Me'
                              ? addAssigneeFilter(displayname)
                              : option === 'QA Assigned To Me'
                                ? addQAAssigneeFilter(displayname)
                                : option === 'Unassigned'
                                  ? addAssigneeFilter('Unassigned')
                                  : addStatusFilter(option)
                          }}
                          label={`${option} (${subTabCount[option] || 0})`}
                          {...(!isSelected && { variant: 'outlined' })}
                        />
                      )
                    })}
                </>
              )}
            </Stack>
            <div className={classes.filterDrop}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="Choose-filter-worflow"
                onChange={(e, newValue) => chooseFilter(e, newValue)}
                options={Object.keys(filterBy)}
                getOptionLabel={(option) => option}
                open={addFilterDropDown}
                onOpen={() => {
                  setAddFilterDropDown(!addFilterDropDown)
                }}
                onClose={() => {
                  setAddFilterDropDown(!addFilterDropDown)
                }}
                value={selectedFiltersValue}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selectedFiltersValue.indexOf(option) > -1}
                    />
                    {filterBy[option].label}
                  </React.Fragment>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={filterBy[option].label}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add Filters"
                    placeholder="Select"
                  />
                )}
              />
            </div>
            <div className={classes.content}>
              {selectedFiltersValue.map((s) => {
                return s === 'launch_dates' ? (
                  <div key={s} style={{ padding: '8px 0' }}>
                    <Typography>{filterBy[s].label}</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        style={{ gap: 32 }}
                      >
                        <KeyboardDatePicker
                          animateYearScrolling
                          className={classes.dateInput}
                          autoOk
                          disableToolbar
                          // disableFuture
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id={s + '-start'}
                          key={s + '-start'}
                          label={'From'}
                          value={
                            (filtersValue['launch_dates'] &&
                              filtersValue['launch_dates']
                                .filter((date) => !isNaN(Date.parse(date)))
                                .map((date) => new Date(date))
                                .sort((a, b) => a - b)[0]) ||
                            null
                          }
                          onChange={(e) => e && handleStartDateChange(e, s)}
                          minDate={
                            filterBy['launch_dates'] &&
                            filterBy['launch_dates'].values
                              .filter((ts) => !isNaN(Date.parse(ts)))
                              .map((ts) => new Date(ts))
                              .sort((a, b) => a - b)[0]
                          }
                          maxDate={
                            filterBy['launch_dates'] &&
                            filterBy['launch_dates'].values
                              .filter((ts) => !isNaN(Date.parse(ts)))
                              .map((ts) => new Date(ts))
                              .sort((a, b) => b - a)[0]
                          }
                        />

                        <KeyboardDatePicker
                          className={classes.dateInput}
                          autoOk
                          disabled={
                            !filtersValue['launch_dates'] ||
                            (filtersValue['launch_dates'] &&
                              !filtersValue['launch_dates'].length > 0)
                          }
                          disableToolbar
                          inputVariant="outlined"
                          // disableFuture
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id={s + '-end'}
                          key={s + '-end'}
                          label={'To'}
                          value={
                            (filtersValue['launch_dates'] &&
                              filtersValue['launch_dates']
                                .filter((ts) => !isNaN(Date.parse(ts)))
                                .map((ts) => new Date(ts))
                                .sort((a, b) => b - a)[0]) ||
                            null
                          }
                          onChange={(e) => e && handleEndDateChange(e, s)}
                          minDate={
                            filtersValue['launch_dates'] &&
                            filtersValue['launch_dates']
                              .filter((ts) => !isNaN(Date.parse(ts)))
                              .map((ts) => new Date(ts))
                              .sort((a, b) => a - b)[0]
                          }
                          maxDate={
                            filterBy['launch_dates'] &&
                            filterBy['launch_dates'].values
                              .filter((ts) => !isNaN(Date.parse(ts)))
                              .map((ts) => new Date(ts))
                              .sort((a, b) => b - a)[0]
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                ) : (
                  <div key={s}>
                    <Autocomplete
                      id={s}
                      limitTags={2}
                      onChange={(e, newValue) => handleChange(e, newValue, s)}
                      multiple
                      disableCloseOnSelect
                      options={filterBy[s].values}
                      inputValue={filterBYInputValue}
                      onInputChange={(event, value, reason) => {
                        if (event && event.type === 'blur') {
                          setfilterByInputValue('')
                        } else if (reason !== 'reset') {
                          setfilterByInputValue(value)
                        }
                      }}
                      getOptionLabel={(option) => option}
                      value={filtersValue && filtersValue[s]}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            id={s}
                            icon={<CheckBoxOutlineBlankIcon size="small" />}
                            checkedIcon={<CheckBoxIcon size="small" />}
                            style={{ marginRight: 8 }}
                            checked={
                              filtersValue &&
                              filtersValue[s]?.indexOf(option) > -1
                            }
                            sx={{
                              '&.Mui-checked': {
                                color: '#CC0000',
                              },
                            }}
                          />
                          {option}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={filterBy[s].label}
                        />
                      )}
                    />
                  </div>
                )
              })}
            </div>
            <div className={classes.buttons}>
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  className={classes.stausButton}
                  onClick={() => {
                    setOpenDrawer('')
                    onFilterDrawerClose()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    isLoading ||
                    addFilterDropDown ||
                    !Object.values(filtersValue).some((x) => x.length)
                  }
                  variant="contained"
                  className={`${classes.stausButton} ${classes.primaryButton}`}
                  onClick={async () => {
                    await setPage(0)
                    await setPageSize(50)
                    applyFilters(0, 50)
                  }}
                >
                  {isLoading ? <CircularProgress size={14} /> : 'Apply'}
                </Button>
              </Stack>
            </div>
          </>
        )
      }
      case 'QA Notes':
        return (
          <>
            <Divider className={classes.drawerHeaderDivider} />
            {reusedWarning()}
            <Grid container className={classes.drawerContainer}>
              <Grid item xs={9} lg={9} md={9}>
                <FormLabel className={classes.tagsLabel}>
                  For {tabWiseSelection.length} selected images
                </FormLabel>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={qaNotes}
                    onChange={(e) => {
                      setQaNotes(e.target.value)
                    }}
                    label="Add Note"
                    multiline
                    rows={10}
                    variant="outlined"
                  />
                </FormControl>
                <Grid>
                  <Stack
                    direction="row"
                    className={classes.buttonGroup}
                    spacing={2}
                  >
                    <Button
                      variant="outlined"
                      className={classes.stausButton}
                      onClick={() => addNotes('qaNotes', 'clear')}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (tabWiseSelection.length > 1) {
                          setConfirmationDailog(true)
                          setConfirmationDailogSection('QA Notes')
                        } else {
                          addNotes('qaNotes', 'add')
                        }
                      }}
                      disabled={
                        originalQaNotes === qaNotes ||
                        isLoadingNotes ||
                        !filteredReusedSelection.length
                      }
                      className={`${classes.stausButton} ${classes.primaryButton}`}
                    >
                      {isLoadingNotes && notesAction === 'add' ? (
                        <CircularProgress size={14} />
                      ) : (
                        'Apply'
                      )}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      case 'Notes':
        return (
          <>
            <Divider className={classes.drawerHeaderDivider} />
            {reusedWarning()}
            <Grid container className={classes.drawerContainer}>
              <Grid item xs={9} lg={9} md={9}>
                <FormLabel className={classes.tagsLabel}>
                  For {tabWiseSelection.length} selected images
                </FormLabel>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value)
                    }}
                    label="Add Note"
                    multiline
                    rows={10}
                    variant="outlined"
                  />
                </FormControl>
                <Grid>
                  <Stack
                    direction="row"
                    className={classes.buttonGroup}
                    spacing={2}
                  >
                    <Button
                      variant="outlined"
                      className={classes.stausButton}
                      onClick={() => addNotes('notes', 'clear')}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      className={`${classes.stausButton} ${classes.primaryButton}`}
                      disabled={
                        originalNotes === notes ||
                        isLoadingNotes ||
                        !filteredReusedSelection.length
                      }
                      onClick={() => {
                        if (tabWiseSelection.length > 1) {
                          setConfirmationDailog(true)
                          setConfirmationDailogSection('Notes')
                        } else {
                          addNotes('notes', 'add')
                        }
                      }}
                    >
                      {isLoadingNotes && notesAction === 'add' ? (
                        <CircularProgress size={14} />
                      ) : (
                        'Apply'
                      )}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      default:
        return <>̦</>
    }
  }

  React.useEffect(() => {
    if (tableTab !== 'SEARCH') {
      // deleteFilters()
      // setUserFilter({ ...activeUserSelectedFilter, [tableTab]: {} })
      // setSubFilter({ ...subChipFilter, [tableTab]: [] })
      setWorkflowState(0, 50, activeUserSelectedFilter[tableTab] || {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableTab])

  React.useEffect(() => {
    debouncedGetWorkflowData(studioList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioList])
  // eslint-disable-next-line
  const debouncedGetWorkflowData = debounce(
    (topStudioList) =>
      setWorkflowState(
        0,
        50,
        activeUserSelectedFilter[tableTab] || {},
        topStudioList
      ),
    1000
  )

  const onSortAndOrderChange = (orderBy, order) => {
    setSorting({ orderBy, order })
    dispatch(
      getWorkflowDataAction(
        lanId,
        accessToken,
        tableTab,
        page,
        pageSize,
        {
          ...activeUserSelectedFilter[tableTab],
          ...(studioList.length && {
            photo_studios: [
              ...(activeUserSelectedFilter[tableTab]?.photo_studios || []),
              ...studioList,
            ],
          }),
          ...(searchedValue.length && {
            image_names: [...new Set(compact(searchedValue.split(/, |,/)))],
          }),
        },
        orderBy,
        order
      )
    )
  }

  return (
    <>
      {openDrawerOrigin !== 'advancedpreview' && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            mt: 8,
          }}
        >
          <ConfirmationDailog
            open={confirmationDailog}
            message={`The changes will update all the ${tabWiseSelection.length} selected images and overwrite any existing notes`}
            onConfirm={handleConfirmationChange}
            onClose={() => setConfirmationDailog(false)}
          />
          <Drawer
            anchor="right"
            open={customizeColumnsIsOpen}
            onClose={closeCustomizeColumns}
          >
            <CustomizeColumnsPanel
              columnSelectionModel={columnSelectionModel[tableTab]}
              handleCustomizeColumnChange={handleCustomizeColumnChange}
              columnView={columnView}
              handleColumnViewChange={handleColumnViewChange}
              saveCustomColumns={saveCustomColumns}
              tabName={tableTab}
              closeCustomizeColumns={closeCustomizeColumns}
            />
          </Drawer>
          <Grid wrap={'nowrap'} container alignItems={'center'}>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <ToggleButtonGroup
                exclusive
                aria-label="status filters"
                sx={{ ml: 4, mt: 3, mb: 4 }}
              >
                {generateToggleButtons()}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <CustomTable
            ref={customTableRef}
            uploadsInProgress={uploadsInProgress}
            tableName={'workflow'}
            tabName={tableTab}
            uniqueIdName={'id'}
            onRowOrderChange={onSortAndOrderChange}
            onImageArchive={setImageToArchiveAction}
            style={{
              height: isSafari()
                ? tabWiseSelection.length
                  ? 'calc(100vh - 360px)'
                  : 'calc(100vh - 330px)'
                : tabWiseSelection.length
                  ? 'calc(100vh - 315px)'
                  : 'calc(100vh - 285px)',
            }}
            onSelectionChange={(data, tabName) => {
              onTableSelection(data, tabName)
            }}
            hasMoreInfo={false}
            isLoading={isLoading}
            page={page}
            rowsPerPage={pageSize}
            totalCount={totalRowCount}
            columns={columns}
            checkBox
            customizeColumns={columnVisibilityModel[tableTab]}
            userColumns={columnVisibilityModel[tableTab]}
            rows={rows}
            Toolbar={
              <TableToolbar
                tableTab={tableTab}
                setWorkflowState={setWorkflowState}
                setCustomizeColumnsIsOpen={setCustomizeColumnsIsOpen}
              />
            }
          />
          <Drawer
            classes={{ paper: classes.actionsDrawer }}
            anchor="bottom"
            open={tabWiseSelection && tabWiseSelection.length > 0}
            variant="persistent"
          >
            <div>
              <span className={classes.footerButton}>
                {`${tabWiseSelection.length} selected`}
              </span>

              <Button
                className={classes.footerButton}
                onClick={handleDownload}
                startIcon={<GetAppIcon />}
              >
                {' '}
                Data
              </Button>
              {csvData.data ? (
                <CSVLink
                  ref={csvBtn}
                  asyncOnClick
                  filename={`${tableTab}.csv`}
                  data={csvData.data}
                  headers={csvData.headers}
                />
              ) : undefined}

              <Button
                className={classes.footerButton}
                onClick={() => handleRetouchContactSheets(tabWiseSelection)}
                disabled={isContactSheetsDownloading}
                startIcon={<GetAppIcon />}
              >
                {' '}
                Contact Sheets
                {isContactSheetsDownloading ? (
                  <CircularProgress style={{ color: '#fff' }} size={14} />
                ) : (
                  <></>
                )}
              </Button>
              <Button
                className={classes.footerButton}
                onClick={
                  tableTab === 'APPROVED'
                    ? openDownloadSheet
                    : () => {
                        downloadList.current = tabWiseSelection.map(
                          (dat) => dat.fileName
                        )
                        const filesToDownload = tabWiseSelection.reduce(
                          (acc, item) => {
                            acc[item.id] = {
                              fileName: item.fileName,
                              url: item.originalImageUrl,
                              status: 'Not Started',
                              timeStamp: Date.now(),
                            }
                            return acc
                          },
                          {}
                        )
                        dispatch(setFilesForDownloads(filesToDownload))
                      }
                }
                startIcon={<GetAppIcon />}
              >
                Images
              </Button>
              {tableTab === 'APPROVED' ? (
                <Menu
                  id="simple-menu"
                  anchorEl={downloadSheetAnchorEl}
                  keepMounted
                  open={Boolean(downloadSheetAnchorEl)}
                  onClose={closeDownloadSheet}
                >
                  <MenuItem
                    onClick={() => {
                      downloadList.current = tabWiseSelection.map(
                        (dat) => dat.fileName
                      )
                      const filesToDownload = tabWiseSelection.reduce(
                        (acc, item) => {
                          acc[item.id] = {
                            fileName: item.fileName,
                            url: item.originalImageUrl,
                            status: 'Not Started',
                            timeStamp: Date.now(),
                          }
                          return acc
                        },
                        {}
                      )
                      dispatch(setFilesForDownloads(filesToDownload))
                    }}
                    disabled={isContactSheetsDownloading}
                    data-item="tiff-images"
                  >
                    Images
                  </MenuItem>
                  <MenuItem
                    data-item="final-jpegs"
                    onClick={() => exportFinalJpegs(tabWiseSelection)}
                  >
                    Final Jpegs
                  </MenuItem>
                </Menu>
              ) : (
                <></>
              )}
              <Button
                className={classes.footerButton}
                onClick={() => handleDownloadSupport(tabWiseSelection)}
                startIcon={<GetAppIcon />}
              >
                Images & Support Images
              </Button>
              {workflowToolbarContents.map((data, key) => {
                const { label = '', access = [] } = data
                return access.some((item) =>
                  memberOf.includes(item.toUpperCase())
                ) ? (
                  <Button
                    className={classes.footerButton}
                    onClick={() => setOpenDrawer(label)}
                    startIcon={getToolbarIcon(label)}
                  >
                    {' '}
                    {label}
                  </Button>
                ) : (
                  <></>
                )
              })}

              <Button
                className={classes.footerButton}
                onClick={() => openAdvancedPreview()}
                startIcon={<ImageIcon />}
              >
                Preview
              </Button>
            </div>
            <IconButton
              aria-label="clear"
              onClick={() => {
                customTableRef.current.setSelect([])
              }}
            >
              <CancelRoundedIcon className={classes.closeIcon} />
            </IconButton>
          </Drawer>
        </Box>
      )}
      <Drawer
        classes={{
          paper:
            openDrawer === 'Manage Tags'
              ? `${classes.tagsDrawer} ${classes.workflowDrawer}`
              : openDrawer === 'Filter By'
                ? `${classes.filterDrawer} ${classes.workflowDrawer}`
                : classes.workflowDrawer,
        }}
        anchor="right"
        open={openDrawer.length}
        onClose={() => {
          if (openDrawer === 'Workflow') {
            setImageWorkflow((prev) => ({
              ...prev,
              image_status: '',
              assignee: '',
              qa_assignee: '',
            }))
            setAssigneeInputValue('')
            setQaAssigneeInputValue('')
          }
          if (openDrawer === 'Filter By') {
            onFilterDrawerClose()
          }
          setOpenDrawer('')
        }}
      >
        <div
          style={{
            height: '100%',
          }}
        >
          <Grid
            wrap={'nowrap'}
            container
            justify={'space-between'}
            alignItems={'center'}
          >
            <h4 className={classes.drawerHeader}>{openDrawer}</h4>
            <IconButton
              aria-label="close"
              disabled={isLoading || isLoadingNotes}
              onClick={() => {
                if (openDrawer === 'Workflow') {
                  setImageWorkflow((prev) => ({
                    ...prev,
                    image_status: '',
                    assignee: '',
                    qa_assignee: '',
                  }))
                  setAssigneeInputValue('')
                  setQaAssigneeInputValue('')
                }
                if (openDrawer === 'Filter By') {
                  onFilterDrawerClose()
                }
                setOpenDrawer('')
              }}
              className={classes.closeIcon}
            >
              <CloseIcon style={{ fontSize: 24 }} />
            </IconButton>{' '}
          </Grid>
          {renderDrawer(openDrawer)}
        </div>
      </Drawer>
    </>
  )
})

export default WorkflowTable
