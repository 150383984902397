import { mapValues } from 'lodash'
import { createWorkFlowFilterData } from '../../helpers/filter'
import {
  SET_USER_FILTER,
  SET_SELECTED_FILTER,
  SET_SUB_FILTER,
  SET_ACTIVE_USER_FILTER,
  RESET_FILTERS,
} from './actionTypes'
import cloneDeep from 'lodash/cloneDeep'
export const initialState = {
  subChipFilter: {
    NEW: [],
    RETOUCH: [],
    COLOR: [],
    HOLD: [],
    REVIEW: [],
    APPROVED: [],
  },
  selectedFilter: {
    NEW: [],
    RETOUCH: [],
    COLOR: [],
    HOLD: [],
    REVIEW: [],
    APPROVED: [],
  },
  userSelectedFilter: {
    NEW: {},
    RETOUCH: {},
    COLOR: {},
    HOLD: {},
    REVIEW: {},
    APPROVED: {},
  },
  activeUserSelectedFilter: {
    NEW: [],
    RETOUCH: [],
    COLOR: [],
    HOLD: [],
    REVIEW: [],
    APPROVED: [],
  },
}
const reducer = (state = initialState, { type, payload = {} } = {}) => {
  const { tab = 'NEW', filters = {}, subFilters = [] } = payload
  switch (type) {
    case SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: { ...state.selectedFilter, [tab]: filters },
      }
    case SET_USER_FILTER:
      return {
        ...state,
        activeUserSelectedFilter: {
          ...state.activeUserSelectedFilter,
          [tab]: filters,
        },
      }
    case SET_SUB_FILTER:
      return {
        ...state,
        subChipFilter: { ...state.subChipFilter, [tab]: subFilters },
      }
    case SET_ACTIVE_USER_FILTER:
      return {
        ...state,
        userSelectedFilter: {
          ...state.userSelectedFilter,
          [tab]: filters,
        },
      }

    case RESET_FILTERS:
      return {
        ...state,
        subChipFilter: { ...state.subChipFilter, [tab]: [] },
        selectedFilter: { ...state.selectedFilter, [tab]: {} },
        userSelectedFilter: { ...state.userSelectedFilter, [tab]: {} },
        activeUserSelectedFilter: {
          ...state.activeUserSelectedFilter,
          [tab]: {},
        },
      }

    default:
      return state
  }
}

export default reducer
